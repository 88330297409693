const config = {
    api: {
        todoist: {
            get: 'https://api.todoist.com/sync/v8/completed/get_all?token=' + process.env.REACT_APP_TODOIST_TOKEN,
            getStats: 'https://api.todoist.com/sync/v8/completed/get_stats?token=' + process.env.REACT_APP_TODOIST_TOKEN,
            getItem: 'https://api.todoist.com/sync/v8/items/get?token=' + process.env.REACT_APP_TODOIST_TOKEN + "&item_id=",
            getLabels: 'https://api.todoist.com/rest/v1/labels?token=' + process.env.REACT_APP_TODOIST_TOKEN,
            getActivity: 'https://api.todoist.com/sync/v8/activity/get?token=' + process.env.REACT_APP_TODOIST_TOKEN,
            getActiveTasks: 'https://api.todoist.com/rest/v1/tasks?token=' + process.env.REACT_APP_TODOIST_TOKEN,
        },
        google: {
            sheets: {
                getTodos: 'https://sheets.googleapis.com/v4/spreadsheets/' + process.env.REACT_APP_SPREADSHEET + '/values:batchGet?ranges=Sheet1&majorDimension=ROWS&key=' + process.env.REACT_APP_SHEETS_API_KEY,
                getFitNotes: 'https://sheets.googleapis.com/v4/spreadsheets/' + process.env.REACT_APP_SPREADSHEET_FITNOTES + '/values:batchGet?ranges=Sheet1&majorDimension=ROWS&key=' + process.env.REACT_APP_SHEETS_API_KEY
            }
        },
        weather: {
            get: 'http://api.openweathermap.org/data/2.5/forecast?APPID=' + process.env.REACT_APP_WEATHER_APP_ID
        }
    }
}

export default config;