import React from 'react'

const ProgressTableRow = (props) => {
    const { row, rowIndex } = props;

    const buildSerialColumn = (rowIndex) => {
        const klass = "serial number";
        const key = klass.split(" ").join("-") + '-row-' + rowIndex;

        const indexValue = rowIndex + 1;
        const value = rowIndex > 9 ? indexValue : "0" + indexValue;

        return <div key={key} className={klass}>{value}</div>;
    }

    const buildImageColumn = (text, image) => {
        const klass = "country image";
        const key = klass.split(" ").join("-") + '-row-' + rowIndex;

        return (
            <div key={key} className={klass}>
                <img src={"images/proficiencies/" + image} alt={text} />
                {text}
            </div>
        );
    }

    const buildPercentageColumn = (value, rowIndex) => {
        const klass = "percentage percentage";
        const key = klass.split(" ").join("-") + '-row-' + rowIndex;

        const colorIndex = rowIndex > 7 ? rowIndex - 7 : rowIndex + 1;

        return (
            <div key={key} className={klass}>
                <div className="progress">
                    <div className={"progress-bar color-" + colorIndex} role="progressbar" style={{ width: value + "%" }}
                        aria-valuenow={value} aria-valuemin="0" aria-valuemax="100"></div>
                    <span className="progress-bar-value">{value}%</span>
                </div>
            </div>
        );
    }

    const { image, percentage, text } = row;

    return (
        <div className="table-row">
            {buildSerialColumn(rowIndex)}
            {buildImageColumn(text, image)}
            {buildPercentageColumn(percentage, rowIndex)}
        </div>
    )
}

export default ProgressTableRow
