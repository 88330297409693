import React, { PureComponent } from 'react'
import Chart from "chart.js";
import "./LineGraphChart.css";

Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif"
Chart.defaults.global.legend.display = false;
Chart.defaults.global.elements.line.tension = 0;

export default class LineGraphChart extends PureComponent {
    componentDidMount() {
        let chartCanvas = this.refs.chart;

        const { labels, title, data, borderColor } = this.props;

        const setGradientFill = (ctx) => {
            let gradientFill = ctx.createLinearGradient(0, 0, 0, 400);
            gradientFill.addColorStop(0, "rgb(255, 0, 0, 0.2)");
            gradientFill.addColorStop(0.5, "rgb(255, 0, 0, 0.35)");
            gradientFill.addColorStop(1, "rgb(255, 0, 0, 0.7)");

            return gradientFill;
        }

        var ctx = document.getElementById('myChart').getContext("2d");

        let lineGraphChart = new Chart(chartCanvas, {
            type: "line",
            data: {
                labels: labels.length === data.length ? labels : new Array(data.length).fill("Data"),
                datasets: [
                    {
                        label: title,
                        data,
                        borderColor,
                        backgroundColor: setGradientFill(ctx)
                    }
                ]
            },
            options: {
                title: {
                    display: true,
                    text: [title, "from " + labels[0] + " to " + labels[labels.length - 1]],
                    fontSize: 18,
                    lineHeight: 1.4
                },
                responsive: true
            }
        });

        this.setState({
            chart: lineGraphChart
        })
    }

    componentDidUpdate() {
        let chart = this.state.chart;

        chart.update();
    }

    render() {
        return (
            <div className="graphContainer ml-2">
                <canvas
                    id="myChart"
                    ref={'chart'}
                />
            </div>
        )
    }
}