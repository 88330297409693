import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ListItem.css'

const listItem = (props) => {
    let classes = ["btn"];
    classes.push(props.classes);

    if (props.active) {
        classes.push("active")
    }

    return (
        <li className={(props.type ? props.type : "square col-6") + " pr-1 pl-1 mb-2"}>
            <button onClick={props.click} className={classes.join(' ')}>
                <span>
                    {props.title}
                    &nbsp;
                </span>
                <FontAwesomeIcon icon={props.icon} />
            </button>
        </li>
    )
}

export default listItem
