
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

// yournextdev.dev
// yournextdeveloper.dev

const initialState = {
    appData: {
        about: {
            name: '',
            occuption: '',
            years: '',
            reason: '',
            contact: {}
        },
        portfolio: {
            tables: {
                headers: [],
                backEnd: {},
                frontEnd: {}
            },
            carousel: [],
            clients: [],
            skills: [],
            services: [],
            certifications: [],
            testimonials: []
        }
    },
    setup: false,
    isFetching: false
}

const setupAppInit = (state, action) => {
    return updateObject(state, { setup: false });
}

const setupAppStart = (state, action) => {
    return updateObject(state, { isFetching: true });
}

const setupAppSuccess = (state, action) => {
    const newAppData = updateObject(state.appData, {
        about: action.data[0],
        portfolio: action.data[1]
    });

    const newState = updateObject(state, {
        appData: newAppData,
        setup: true,
        isFetching: false
    });

    return newState;
}

const setupAppFail = (state, action) => {
    return updateObject(state, { isFetching: false });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SETUP_APP_INIT: return setupAppInit(state, action);
        case actionTypes.SETUP_APP_START: return setupAppStart(state, action);
        case actionTypes.SETUP_APP_SUCCESS: return setupAppSuccess(state, action);
        case actionTypes.SETUP_APP_FAIL: return setupAppFail(state, action);
        default: return state;
    }
}

export default reducer;
