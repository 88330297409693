import React from 'react'
import BriefListItem from './BriefListItem/BriefListItem'

const briefList = (props) => {
    const { title, data, itemId, property, badges, column } = props;

    let listItem;

    if (data) {
        listItem = data.map((label, index) => (
            <BriefListItem item={label}
                id={itemId}
                badges={badges}
                text="Completed"
                property={property}
                key={itemId + index} />
        ));
    }

    return (
        <div className={`col-` + (column || 4)}>
            <div className="card-title text-center pb-2 mb-3">
                {title}
            </div>
            <ul>
                {listItem}
            </ul>
        </div>
    )
}

export default briefList
