import React from 'react'

const footer = () => {
    return (
        <footer className="footer_part">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="social_icon">
                            <a href="/"><i className="ti-twitter-alt"></i></a>
                            <a href="/"><i className="ti-dribbble"></i></a>
                            <a href="/"><i className="ti-instagram"></i></a>
                            <a href="/"><i className="ti-pinterest"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default footer
