import 'firebase/auth';
import 'firebase/firestore';

import firebase from 'firebase/app';
import { authState } from 'rxfire/auth';
import { collectionData } from 'rxfire/firestore';
import { filter } from 'rxjs/operators';

const config = {
    apiKey: 'AIzaSyDMx6zY_lI5t-HWI1k6Aw6_omfYJxDzfNo',
    authDomain: 'sheets-retriever.firebaseapp.com',
    databaseURL: 'https://sheets-retriever.firebaseio.com',
    projectId: 'sheets-retriever',
    messagingSenderId: '382157247341',
    appId: '1:382157247341:web:cf161055e52c148f'
};

const app = firebase.initializeApp(config);

const firestore = firebase.firestore(app); // Initialize firestore
const auth = firebase.auth(app); // Initialize firebase auth
const loggedIn$ = authState(auth).pipe(filter(user => !!user)); // Observable only return when user is logged in.

firestore.settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firestore.enablePersistence()
    .catch((err) => {
        if (err.code === 'failed-precondition') {
            console.info("Multiple tabs open. Persistence can only be enabled in one tab at a time.");
        } else if (err.code === 'unimplemented') {
            console.info("Current browser does not support persistence");
        }

        console.log(err.code);
        console.log(err);
    });

export { app, auth, firestore, collectionData, loggedIn$ };

export default firebase;