import React from 'react'

const SectionTitle = (props) => {
    const { title } = props;

    return (
        <div className="row justify-content-center">
            <div className="col-xl-5">
                <div className="section_tittle text-center">
                    <h2>{title}</h2>
                </div>
            </div>
        </div>
    )
}

export default SectionTitle;
