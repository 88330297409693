import React from 'react'
import SectionTitle from '../Sections/SectionTitle/SectionTitle'
import Testimonial from './Testimonial/Testimonial'

const Testimonials = (props) => {
    const { testimonials } = props;

    return (
        <section className="review_part padding_bottom">
            <div className="container">
                <SectionTitle title={"Testimonials"}></SectionTitle>
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-10">
                        <div className="slider text-center">
                            {testimonials.map((testimonial, index) => {
                                return <Testimonial
                                    key={"testimonial-key-" + index}
                                    testimonial={testimonial}></Testimonial>;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials
