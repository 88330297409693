import React from 'react';
import './BriefListItem.css'
import BadgeHelpers from '../../../../../helpers/badge.helpers';

const briefListItem = (props) => {
    const { item, id, badges, text, property } = props;

    return (
        <li key={id + item.key} className="d-flex justify-content-between mb-2">
            <span className="badge" style={{
                background: '#' + BadgeHelpers.getBadgeFromLabel(badges, item.key).color,
                color: 'white'
            }}>
                {item.key}
            </span>
            <b className="text-muted"> {text} {item[property]}</b>
        </li>
    )
}

export default briefListItem
