import React from 'react'
import Auxilary from '../../../../../hoc/Auxilary/Auxilary'

const actions = (props) => {
    return (
        <Auxilary>
            <li className="app-sidebar__heading">{props.header}</li>
            <ul className={(!props.row ? "" : "row") + " actions pl-0 pt-0"}>
                {props.children}
            </ul>
        </Auxilary>
    )
}

export default actions
