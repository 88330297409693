import React from 'react'
import Auxilary from '../../hoc/Auxilary/Auxilary';
import "./Banner.css";

const Banner = (props) => {
    const { name, occupation } = props;

    return (
        <Auxilary>
            <section className="banner_part">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="banner_text">
                                <div className="banner_text_iner">
                                    <h5>&lt;Hey /&gt;</h5>
                                    <h1>{name}</h1>
                                    <p>
                                        Ready to serve you as <b>{occupation}</b>.
                                    </p>
                                    <div className="row">
                                        <div className="col-md-2 qr_code">
                                            <img src='images/github_qr_code.png' alt='Github' />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="banner_btn">
                                                <a href="/" className="genric-btn primary main-btn">Hire me</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">

                        </div>
                    </div>
                </div>
            </section>
        </Auxilary>
    )
}

export default Banner
