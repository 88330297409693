import React from 'react'

import './DrawerToggle.css'

const drawerToggle = (props) => {
    return (
        <span className={"DrawerToggle menu-trigger visible-xs"} onClick={props.clicked}>
            <span></span>
            <span></span>
            <span></span>
        </span>
    )
}

export default drawerToggle;
