import React from 'react';
import './Cloud.css';

const cloud = (props) => {
    return (
        <ul className="label-cloud">
            {props.badges.map((label, j) => (
                <li key={j + 'badge'}>
                    <span className={'badge'} style={{ background: '#' + label.color, color: 'white' }}>{label.name} {label.count}</span>
                </li>
            ))}
        </ul>
    )
}

export default cloud
