import React from 'react'

const breadcrumb = (props) => {
    const { title, background } = props;

    return (
        <section className={"breadcrumb breadcrumb_bg " + background}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb_iner text-center">
                            <div className="breadcrumb_iner_item">
                                <h2>{title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default breadcrumb;