import SpreadsheetHelpers from './spreadsheet.helpers';

const BadgeHelpers = {
    toBadges: (values) => {
        let labelCount = {};

        const countLabel = (label) => {
            if (labelCount[label]) {
                labelCount[label] += 1;
            } else {
                labelCount[label] = 1;
            }
        }

        let badges = values.map(task => {
            if (task[3] === '') {
                countLabel('None');

                return ['None'];
            } else {
                return task[3].replace(' ', '').split(',').map(label => {
                    let l = label.replace(' ', '');

                    countLabel(l);

                    return l;
                })
            }
        });

        badges = [...new Set([].concat(...badges))].map((label, i) => {
            return {
                name: label,
                count: labelCount[label],
                color: SpreadsheetHelpers.genColor(i + 1)
            }
        });

        badges[0] = { name: 'None', color: SpreadsheetHelpers.genColor(1), count: labelCount['None'] };

        return badges;
    },

    labelToBadge: (label, index) => {
        return {
            id: label.id,
            name: label.name,
            color: SpreadsheetHelpers.genColor(index + 1)
        }
    },

    getBadgeFromLabel: (badges, label) => {
        return badges.filter(badge => {
            return badge.name === label;
        })[0];
    }
}

export default BadgeHelpers;