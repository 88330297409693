import React from 'react';

import { NavLink } from 'react-router-dom'
import './Menu.css'
import '../../../Widget.css';

const menu = (props) => {
    return (
        <div className="app-header__logo">
            <div className="logo-src">
                <h1 className="menu-header">
                    YouUpgraded
                </h1>
            </div>
            <div className="header__pane ml-auto">
                <div>
                    <div className="btn-group border-bottom-0">
                        <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                        <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right">
                            <h6 tabIndex="-1" className="dropdown-header">Dashboards</h6>
                            <NavLink
                                to={'/dashboard'}
                                exact={true}
                                activeClassName={"active"}
                                className="dropdown-item">
                                {'Todoist'}
                            </NavLink>
                            <NavLink
                                to={'/dashboard/fitness'}
                                activeClassName={"active"}
                                className="dropdown-item">
                                {'Fitness'}
                            </NavLink>
                            <NavLink
                                to={'/dashboard/groceries'}
                                activeClassName={"active"}
                                className="dropdown-item">
                                {'Groceries'}
                            </NavLink>
                            <div tabIndex="-1" className="dropdown-divider"></div>
                            <h6 tabIndex="-1" className="dropdown-header">Other</h6>
                            <NavLink
                                to={'/dashboard/mini'}
                                activeClassName={"active"}
                                className="dropdown-item">
                                {'Mini Projects'}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default menu
