import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faLink } from '@fortawesome/free-solid-svg-icons';

import './WorkoutDefinition.css'

import Card from '../../Panel/Card/Card'
import ButtonToolbar from '../../ButtonToolbar/ButtonToolbar';
import FitnessCardItem from '../../Panel/Card/FitnessCardItem/FitnessCardItem'
import IconHelper from '../../../helpers/icon.helpers';
import LinkHelper from '../../../helpers/link.helpers';
import DifficultyIcons from '../DifficultyIcons/DifficultyIcons';
import StringHelper from '../../../helpers/string.helpers';

class WorkoutDefinition extends Component {
    render() {
        const { categories, workout, addExercise, suggestExercise, isOpen } = this.props;
        const { category, name, goal, duration, difficulty, link, note } = workout;

        const catIcon = IconHelper.getCategoryIcon(category);
        const { icon, background, color } = categories[category];

        let hours = (duration / 60).toFixed(1)

        return (
            <Card title={goal}
                item={{ key: "wd-" + name }}
                icon={{ fa: catIcon, color: icon.color, size: 'lg' }}
                key={"workout-definition"}
                style={{
                    background: background,
                    color: color
                }}
                collapsible={true}
                isCollapsed={isOpen}
                footer={
                    <div className="d-flex justify-content-between w-100">
                        <DifficultyIcons
                            difficulty={5}
                            size='lg'
                            defaultValue={difficulty} />

                        <b className="text-muted">
                            {hours} hours <FontAwesomeIcon icon={faClock} size="lg" />
                        </b>
                    </div>
                }>
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon className="mr-1" icon={faLink} />
                    {LinkHelper.getHostName(link)}
                </a>
                <p>{note}</p>
                <ul className="list-group list-group-flush">
                    {workout.exercises.map((exercise, index) => {
                        const { note, difficulty, sets } = exercise;
                        return (
                            <FitnessCardItem
                                key={`workout-definition-exercise-${index}`}
                                name={exercise.name}
                                useEmotions={false}
                                editable={false}
                                note={note}
                                difficulty={difficulty}
                                setsBuilder={{
                                    addSet: () => { },
                                    removeSet: () => { },
                                    changeSet: () => { },
                                    sets: sets || [],
                                    exerciseIndex: index,
                                    id: StringHelper.cleanToLower(exercise.name).replace(/\s/g, '')
                                }}
                            />
                        )
                    })}
                </ul>
                <footer className={link ? "d-none" : 'mt-3'}>
                    <ButtonToolbar label={"Quick Actions"} buttonClick={[
                        addExercise,
                        suggestExercise
                    ]} />
                </footer>
            </Card>
        )
    }
}

export default WorkoutDefinition
