import React, { Component } from 'react'
import moment from 'moment'
import _ from 'lodash';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faBrain, faTimes, faCalendarDay, faCloudRain, faClock, faEye, faEyeSlash, faSave } from '@fortawesome/free-solid-svg-icons'

import FirebaseService from '../../services/firebase.service';
import WeatherService from '../../services/weather.service';
import IconHelper from '../../helpers/icon.helpers';
import StringHelper from '../../helpers/string.helpers';

import './CalendarContainer.css'
import Card from '../../components/Panel/Card/Card'
import Autocomplete from '../../../../components/Autocomplete/Autocomplete';
import FitnessCardItem from '../../components/Panel/Card/FitnessCardItem/FitnessCardItem';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import CalendarToolbar from '../../components/Fitness/CalendarToolbar/CalendarToolbar';
import Scheduler from '../../components/Fitness/Scheduler/Scheduler';

class CalendarContainer extends Component {
    state = {
        events: [],

        tempEvent: {},
        showModal: false,
        showScheduler: false,

        selectedDay: null,
        saved: false,
        weather: null,

        filteredWorkouts: [],
        showExercises: [],

        days: []
    }

    constructor(props) {
        super(props);

        this.openEventModal = this.openEventModal.bind(this);
        this.closeEventModal = this.closeEventModal.bind(this);
        this.updateTempEventName = this.updateTempEventName.bind(this);
        this.moveEvent = this.moveEvent.bind(this)
        this.resizeEvent = this.resizeEvent.bind(this)

        this.addNewDay = this.addNewDay.bind(this);
        this.selectDay = this.selectDay.bind(this);
        this.saveSelectedDay = this.saveSelectedDay.bind(this);

        this.addWorkoutToDay = this.addWorkoutToDay.bind(this);
        this.suggestWorkoutForDay = this.suggestWorkoutForDay.bind(this);
        this.filterWorkouts = this.filterWorkouts.bind(this);

        this.addSet = this.addSet.bind(this);
        this.removeSet = this.removeSet.bind(this);
        this.changeSet = this.changeSet.bind(this);
        this.toggleExercises = this.toggleExercises.bind(this);

        this.buildSchedule = this.buildSchedule.bind(this);
        this.toggleScheduler = this.toggleScheduler.bind(this);
    }

    componentDidMount() {
        const { days } = this.props.data;
        const today = moment().toDate();
        let selectedDay;

        let events = (days || []).map(day => {
            const date = moment.unix(day.date.seconds).toDate();

            if (moment(date).isSame(today, "day")) {
                selectedDay = {
                    ...day
                }
            }

            return {
                title: day.name,
                start: moment.unix(day.from.seconds).toDate(),
                end: moment.unix(day.to.seconds).toDate()
            }
        });

        if (selectedDay) {
            WeatherService.get("zip", 98004)
                .then(weather => {
                    this.setState({
                        ...this.state,
                        events: events,
                        selectedDay,
                        weather
                    })
                })
        } else {
            this.setState({
                ...this.state,
                events: events
            })
        }
    }

    openEventModal({ start, end }) {
        this.setState({
            ...this.state,
            tempEvent: { start, end },
            showModal: true
        })
    }

    closeEventModal() {
        this.setState({
            ...this.state,
            tempEvent: {},
            showModal: false
        });
    }

    moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }) {
        const { events } = this.state

        const idx = events.indexOf(event)
        let allDay = event.allDay

        if (!event.allDay && droppedOnAllDaySlot) {
            allDay = true
        } else if (event.allDay && !droppedOnAllDaySlot) {
            allDay = false
        }

        const updatedEvent = { ...event, start, end, allDay }

        const nextEvents = [...events]
        nextEvents.splice(idx, 1, updatedEvent)

        this.setState({
            events: nextEvents,
        })
    }

    resizeEvent = ({ event, start, end }) => {
        const { events } = this.state

        const nextEvents = events.map(existingEvent => {
            return existingEvent.id === event.id
                ? { ...existingEvent, start, end }
                : existingEvent
        })

        this.setState({
            events: nextEvents,
        })
    }

    updateTempEventName(e) {
        const { value } = e.target;

        this.setState({
            ...this.state,
            tempEvent: {
                ...this.state.tempEvent,
                title: value
            }
        });
    }

    addNewDay() {
        const { tempEvent } = this.state;
        const { title, start, end } = tempEvent;

        FirebaseService.addOrUpdateDay(
            {
                name: title,
                from: start,
                to: end,
                date: moment(start).startOf('day').toDate()
            },
            this.props.user.id)
            .then(() => {
                this.setState({
                    events: [
                        ...this.state.events,
                        {
                            ...tempEvent
                        }
                    ],
                    tempEvent: {},
                    showModal: false
                });

                this.selectDay(tempEvent);
            });
    }

    selectDay(event) {
        console.log(event)
        const { days } = this.props.data;

        const index = _.findIndex(days, thisDay => {
            const date = moment.unix(thisDay.date.seconds).toDate();
            const from = moment.unix(thisDay.from.seconds).toDate();
            const to = moment.unix(thisDay.to.seconds).toDate();

            const checkFrom = from.getTime() === event.start.getTime();
            const checkTo = to.getTime() === event.end.getTime();
            const checkDate = moment(event.start).isSame(date, "day");

            return checkDate && checkFrom && checkTo;
        });

        this.setState({
            ...this.state,
            selectedDay: days[index]
        })
    }

    filterWorkouts(value) {
        FirebaseService.filterWorkouts(this.props.user.id, value)
            .then(filteredWorkouts => {
                this.setState({
                    ...this.state,
                    filteredWorkouts
                });
            });
    }

    addWorkoutToDay(selectedOption) {
        let workouts = this.state.selectedDay.workouts ? [...this.state.selectedDay.workouts] : [];
        const index = _.findIndex(workouts, workout => { return _.isMatch(workout, selectedOption); });

        if (index === -1) {
            workouts[workouts.length] = { ...selectedOption };

            this.setState({
                ...this.state,
                selectedDay: {
                    ...this.state.selectedDay,
                    workouts
                }
            })
        }
    }

    suggestWorkoutForDay() {

    }

    saveSelectedDay() {
        FirebaseService.addOrUpdateDay(this.state.selectedDay, this.props.user.id)
            .then(() => {
                this.setState({
                    ...this.state,
                    saved: true
                });
            });
    }

    removeSet(setIndex, exerciseIndex, workoutIndex) {
        let { selectedDay } = this.state;

        let { workouts } = selectedDay;
        let workout = workouts[workoutIndex];
        let exercises = [...workout.exercises];

        let exercise = exercises[exerciseIndex];
        let sets = exercise.sets;
        sets.splice(setIndex, 1);

        exercises[exerciseIndex] = exercise;
        workout.exercises = exercises;
        workouts[workoutIndex] = workout;

        this.setState({
            ...this.state,
            selectedDay: {
                ...this.state.selectedDay,
                workouts
            }
        })
    }

    changeSet(e, setIndex, exerciseIndex, workoutIndex, type) {
        let { name, value } = e.target;
        let { selectedDay } = this.state;

        let { workouts } = selectedDay;
        let workout = workouts[workoutIndex];
        let exercises = [...workout.exercises];

        let exercise = exercises[exerciseIndex];
        let sets = exercise.sets;
        let set = sets[setIndex];

        if (type) {
            let time = (set[name] || "00:00:00").split(":");
            switch (type) {
                case 'minutes':
                    time[1] = value;
                    break;
                case 'seconds':
                    time[2] = value;
                    break;
                case 'hours':
                default:
                    time[0] = value;
                    break;
            }
            value = time.join(":");
        }

        set[name] = value;

        sets[setIndex] = set;
        exercises[exerciseIndex] = exercise;
        workout.exercises = exercises;
        workouts[workoutIndex] = workout;

        this.setState({
            ...this.state,
            selectedDay: {
                ...this.state.selectedDay,
                workouts
            }
        })
    }

    addSet(exerciseIndex, workoutIndex) {
        let { selectedDay } = this.state;

        let { workouts } = selectedDay;
        let workout = workouts[workoutIndex];

        let exercises = [...workout.exercises];
        let exercise = exercises[exerciseIndex];

        if (!exercise.sets) {
            exercise.sets = []
        }

        exercise.sets.push({ weight: 0, reps: 0, distance: 0, time: "00:00:00" });

        exercises[exerciseIndex] = exercise;
        workout.exercises = exercises;
        workouts[workoutIndex] = workout;

        this.setState({
            ...this.state,
            selectedDay: {
                ...this.state.selectedDay,
                workouts
            }
        })
    }

    toggleExercises(index) {
        const showExercises = [...this.state.showExercises];
        const showExercise = !showExercises[index];

        showExercises[index] = showExercise;

        this.setState({
            ...this.state,
            showExercises
        })
    }

    buildSchedule(schedule, callback) {
        let { startDate, endDate, catPattern, skip, workoutPattern } = schedule;

        startDate = startDate || 'A';
        endDate = endDate || 'A';
        catPattern = catPattern || 'A';
        workoutPattern = workoutPattern || 'A';
        skip = skip || 3;
        // restDays = restDays || 1;

        let numOfDays = 0;

        try {
            let fromDate;

            if (startDate === 'A' || endDate === 'A') {
                numOfDays = Math.ceil(Math.random() * 60);
                fromDate = new Date();
            } else {
                fromDate = startDate.toDate();
                numOfDays = endDate.diff(startDate, 'days');
            }

            let days = [];

            const { categories, data } = this.props;
            const { workout_definitions } = data;

            let catIndex = 0;

            for (let i = 0, j = 0; i < numOfDays; i++) {
                if (j < skip) {
                    let day = {};

                    day.day = moment(fromDate).add(i, "day").toDate();
                    day.from = day.day;
                    day.to = day.day;

                    if (catPattern.length - 1 <= catIndex) {
                        catIndex = 0;
                    }

                    let cp = catPattern.substring(catIndex, catIndex + 1);

                    const randomCat = Math.floor(Math.random() * categories.length);
                    cp = cp === 'A' ? categories.map(x => x.name.substring(0, 1).toUpperCase())[randomCat] : cp;

                    let cat = _.find(categories, c => {
                        return c.name.substring(0, 1) === cp
                    });

                    let cIndex = _.findIndex(categories, c => {
                        return c.name.substring(0, 1) === cp
                    })

                    day.name = cat.name;

                    const catWorkouts = workout_definitions.filter(w => {
                        return w.category.toString() === cIndex.toString();
                    });

                    const catWorkoutsLength = catWorkouts.length;

                    const mainWorkoutIndex = Math.floor(Math.random() * catWorkoutsLength);
                    const mainWorkout = catWorkouts[mainWorkoutIndex];

                    let workouts = [];

                    // Gotta check if the same workout gets added...
                    for (let j = 0; j < workoutPattern.length; j++) {
                        const wp = workoutPattern[j];
                        let workout = {};
                        let defs;
                        switch (wp) {
                            case 'W':
                                workout = mainWorkout;
                                break;
                            case 'R':
                                defs = workout_definitions.filter(w => {
                                    return w.category === "3";
                                });
                                workout = defs[Math.floor(Math.random() * defs.length)];
                                break;

                            default:
                                workout = mainWorkout;
                                break;
                        }

                        workouts.push(workout);
                    }

                    day.workouts = workouts;

                    days.push(day);
                    catIndex++;

                    j++;
                } else if (j >= skip) {
                    j = 0;
                }
                console.log("Index", j)
            }

            console.log(days);
            callback();
            this.setState({
                ...this.state,
                days
            });
        } catch (error) {
            console.error(error);
        }
    }

    toggleScheduler() {
        this.setState({
            ...this.state,
            showScheduler: !this.state.showScheduler
        })
    }

    render() {
        const { events, showModal, tempEvent, selectedDay, filteredWorkouts, days, saved, weather, showScheduler } = this.state;
        const { categories, feelings } = this.props;

        let daySelected;
        if (selectedDay) {
            const date = moment.unix(selectedDay.date.seconds).toDate();
            let dateTitle = moment(date).format("LLL");

            let totalTime = 0;

            if (selectedDay.workouts) {
                selectedDay.workouts.map(w => totalTime += parseInt(w.duration, 10));

                totalTime = (totalTime / 60).toFixed(1);
            }

            let weatherIcon = faCalendarDay;

            if (weather) {
                const cityWeather = weather.list[0].weather[0];
                if (cityWeather) {
                    switch (cityWeather.main.toLowerCase()) {
                        case 'rain':
                            weatherIcon = faCloudRain
                            break;

                        default:
                            break;
                    }
                }
            }

            daySelected = (
                <>
                    <div className="light-header text-center">
                        <h6 className="text-muted">
                            {dateTitle}
                            {saved ? <FontAwesomeIcon icon={faCheckCircle} color="darkgreen" /> : null}
                        </h6>
                    </div>
                    <Card title="Workouts"
                        item={{ key: selectedDay.name, data: [] }}
                        icon={{ fa: weatherIcon, color: 'white', size: 'lg' }}
                        style={{
                            background: 'black',
                            color: 'white'
                        }}
                        footer={
                            <b className="text-muted">
                                {totalTime} hours
                                <FontAwesomeIcon className="ml-2" icon={faClock} />
                            </b>
                        }>

                        <ul className="list-group list-group-flush mb-3">
                            {(selectedDay.workouts || []).map((workout, index) => {
                                return (
                                    <li key={workout.name} className={(index === 0 ? "border-top " : "") + "list-group-item align-items-center flex-column d-flex pl-0 pr-0"}>
                                        <div className="list-group-item-body justify-content-between w-100 d-flex">
                                            <h6 className="mb-1">{workout.name}</h6>
                                            <small>
                                                {workout.duration} minutes
                                                &nbsp; | &nbsp;
                                                <FontAwesomeIcon icon={this.state.showExercises[index] ? faEye : faEyeSlash} onClick={() => this.toggleExercises(index)} className="pointer" />
                                            </small>
                                        </div>
                                        <ul className={this.state.showExercises[index] ? "w-100 workout-exercises mt-2 list-group list-group-flush" : "d-none"}>
                                            {(workout.exercises || []).map((exercise, eIndex) => {
                                                return (
                                                    <FitnessCardItem
                                                        key={exercise.name + "-key-exercise-" + eIndex}
                                                        data={{ feelings }}
                                                        name={exercise.name}
                                                        useEmotions={true}
                                                        editable={false}
                                                        note={exercise.note}
                                                        difficulty={exercise.difficulty}
                                                        setsBuilder={{
                                                            addSet: () => this.addSet(eIndex, index),
                                                            removeSet: this.removeSet,
                                                            changeSet: this.changeSet,
                                                            sets: exercise.sets,
                                                            exerciseIndex: eIndex,
                                                            workoutIndex: index,
                                                            id: StringHelper.cleanToLower(exercise.name).replace(/\s/g, '')
                                                        }}
                                                    />
                                                );
                                            })}
                                        </ul>
                                    </li>
                                )
                            })}
                            <li className="list-group-item d-flex align-items-center pl-0 border-top-0 border-bottom-0 pt-0 pr-0">
                                <Autocomplete
                                    options={filteredWorkouts}
                                    onChange={this.filterWorkouts}
                                    placeholder="Search and add a workout"
                                    btnOnClick={this.addWorkoutToDay}
                                />
                            </li>
                        </ul>
                        <div className="btn-group float-right" role="group" aria-label="Quick Actions">
                            <button type="button" className="btn btn-lg btn-warning" title="Save selected day" onClick={this.saveSelectedDay}>
                                <FontAwesomeIcon icon={faSave} size="lg" />
                            </button>
                            <button
                                type="button"
                                className="btn btn-dark btn-lg dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                title="Suggest a workout form a category">
                                <FontAwesomeIcon icon={faBrain} />
                            </button>
                            <div className="dropdown-menu">
                                <h6 className="dropdown-header">Suggest a Workout from</h6>
                                {categories.map(category => {
                                    const categoryIcon = IconHelper.getCategoryIcon(category.icon.name);
                                    const color = category.icon.color === "white" ? "black" : category.icon.color;

                                    return (
                                        <button
                                            className="dropdown-item d-flex justify-content-between"
                                            key={category.name}
                                            onClick={() => this.suggestWorkoutForDay(category.id)}>
                                            {category.name}
                                            <FontAwesomeIcon
                                                icon={categoryIcon}
                                                color={color}
                                                size="lg" />
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    </Card>
                </>
            );
        } else {
            daySelected = (
                <header>
                    <h3 className="text-center text-muted">
                        No Date Selected
                    </h3>
                </header>)
        }

        return (
            <>
                {showScheduler ? <Scheduler build={this.buildSchedule} days={days} toggle={this.toggleScheduler} categories={categories} /> : null}
                <div className="row">
                    <div className="col-md-3 col-sm-12">
                        <CalendarToolbar toggleScheduler={this.toggleScheduler} />
                        <div className="mb-2"></div>
                        {daySelected}
                    </div>

                    <div className="col-md-9 col-sm-12">
                        <Calendar
                            selectable
                            resizable
                            localizer={momentLocalizer(moment)}
                            events={events}
                            views={{ month: true, week: true, agenda: true }}
                            defaultView={Views.WEEK}
                            startAccessor="start"
                            endAccessor="end"
                            onEventDrop={this.moveEvent}
                            onEventResize={this.resizeEvent}
                            onSelectSlot={this.openEventModal}
                            onSelectEvent={this.selectDay} />

                        <div className={showModal ? "absolute event-modal p-2" : "d-none"}>
                            <header className="d-flex justify-content-between">
                                <h5>Name for Workout Group</h5>
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    size='lg'
                                    onClick={this.closeEventModal}
                                    className="pointer" />
                            </header>
                            <div className="btn-group w-100">
                                <input type="text"
                                    className="form-control"
                                    placeholder="Add name to workout group"
                                    defaultValue={tempEvent.title}
                                    onChange={this.updateTempEventName} />
                                <button className="btn btn-danger"
                                    onClick={this.addNewDay}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CalendarContainer
