import React from 'react'
import './Card.css';
import CardItem from './CardItem/CardItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const card = (props) => {
    const { title, item, icon, footer, style, isCollapsed, collapsible } = props;

    const { background, color } = style;

    let faIcon;
    let data;

    if (icon) {
        faIcon = <FontAwesomeIcon size={icon.size} style={{ color: icon.color }} icon={icon.fa} />
    }

    if (item.data && item.data.length > 0) {
        data = (<ul>
            {item.data.map((task, i) => (
                <CardItem index={i}
                    item={task}
                    key={title + i} />
            ))}
        </ul>);
    } else {
        data = props.children;
    }

    const id = item.key && item.key.split(' ').join('_');

    return (
        <div className="card mt-3" key={item.key} id={id}>
            <div className="card-header d-flex justify-content-between h5 pointer"
                style={{
                    background: background,
                    color: color
                }}
                data-toggle="collapse" data-target={`#${id} .card-body-collapse, #${id} .card-footer-collapse`}>
                <span>{(item.key || '').replace('wd-', '')}</span>
                {faIcon}
            </div>
            <div className={(collapsible && isCollapsed ? 'collapse' : '') + " card-body card-body-collapse"}>
                <h3 className="card-title mb-2 text-muted">
                    {title}
                </h3>
                {data}
            </div>
            <div className={(collapsible && isCollapsed ? 'collapse' : '') + " card-footer card-footer-collapse justify-content-end"}>
                {footer}
            </div>
        </div>
    )
}

export default card
