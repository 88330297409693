import React from 'react';
import './Bouncer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

const bouncer = (props) => {
    let icon;

    if (props.isClicked) {
        icon = <FontAwesomeIcon icon={faCaretLeft} className="bouncer-icon" />;
    } else {
        icon = <FontAwesomeIcon icon={faCaretRight} className="bouncer-icon" />;
    }

    return (
        <div className="bouncer d-flex justify-content-center align-items-center" onClick={props.toggleBouncer}>
            {icon}
        </div>
    )
}

export default bouncer
