import React from 'react'
import SectionTitle from '../Sections/SectionTitle/SectionTitle';
import Client from './Client/Client';

const Clients = (props) => {
    const { title, clients } = props;

    const items = (clients || []).map((client, index) => {
        const { title, location, image } = client;

        return (
            <Client
                key={"client-key-" + index}
                title={title}
                location={location}
                image={"img/gallery/" + image}></Client>
        );
    })

    return (
        <section className="gallery_part section_padding">
            <div className="container">
                <SectionTitle title={title}></SectionTitle>
                <div className="row">
                    <div className="col-xl-12">
                        {items}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Clients
