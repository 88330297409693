import React, { Component } from 'react'
import SectionTitle from '../Sections/SectionTitle/SectionTitle';
import CarouselItem from './CarouselItem/CarouselItem';
import CarouselLogoItem from './CarouselLogoItem/CarouselLogoItem';
import Loadable from '@loadable/component';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./Carousel.css";

const OwlCarousel = Loadable(() => import('react-owl-carousel'));

class Carousel extends Component {
    state = {
        isMounted: false
    }

    componentDidMount() {
        this.setState({
            isMounted: true
        });
    }

    render() {
        if (!this.state.isMounted) {
            return null;
        }

        const { title, isLogo, items } = this.props;

        const carouselItems = items.map((c, index) => {
            let item = null;
            if (isLogo) {
                item = <CarouselLogoItem
                    key={"carousel-key-" + index} src={"images/companies/" + c.src}></CarouselLogoItem>
            } else {
                item = <CarouselItem
                    key={"carousel-key-" + index}></CarouselItem>
            }

            return item;
        });

        return (
            <section className="client_part section_padding">
                <div className="container">
                    <SectionTitle title={title}></SectionTitle>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            {
                                items.length && <OwlCarousel
                                    items={3}
                                    className="owl-theme client_logo"
                                    loop
                                    nav
                                    autoplay
                                    autoplayHoverPause
                                    autoplayTimeout="5000"
                                    smartSpeed="2000"
                                    margin={20}>
                                    {carouselItems}
                                </OwlCarousel>
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Carousel;
