import React, { Component } from 'react';

import Auxilary from '../Auxilary/Auxilary';
import './Layout.css';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import Footer from '../../components/Navigation/Footer/Footer';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';

class Layout extends Component {
    state = {
        showSideDrawer: false
    }

    sideDrawerClosedHandler = () => {
        this.setState({ showSideDrawer: false });
    }

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer }
        });
    }

    render() {
        const header = this.props.renderNav ? <Toolbar drawerToggleClicked={this.sideDrawerToggleHandler} /> : <div></div>;
        const footer = this.props.renderNav ? <Footer /> : <div></div>;
        const drawer = this.props.renderNav ? <SideDrawer open={this.state.showSideDrawer} closed={this.sideDrawerClosedHandler} /> : <div></div>;

        return (
            <Auxilary>
                {header}
                {drawer}
                <main className="Content">
                    {this.props.children}
                </main>
                {footer}
            </Auxilary>
        )
    }
}

export default Layout;
