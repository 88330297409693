import React, { Component } from 'react'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faAngleUp, faAngleDown, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import './Scheduler.css';

class Scheduler extends Component {
    state = {
        catPattern: '', // like CSBR
        restDays: 1,
        skip: 3,
        workoutPattern: '', // like RWR

        startDate: null,
        endDate: null,
        focusedInput: 'startDate',

        slide: false
    }

    constructor(props) {
        super(props);

        this.toggleScreens = this.toggleScreens.bind(this);
    }

    addCategory(letter) {
        let catPattern = this.state.catPattern;
        catPattern += letter;

        this.setState({ ...this.state, catPattern });
    }

    addWorkout(letter) {
        let workoutPattern = this.state.workoutPattern;
        workoutPattern += letter;

        this.setState({ ...this.state, workoutPattern });
    }

    setRestDays(restDays) {
        this.setState({ ...this.state, restDays });
    }

    setSkipDays(skip) {
        this.setState({ ...this.state, skip });
    }

    toggleScreens() {
        this.setState({ ...this.state, slide: !this.state.slide })
    }

    render() {
        const { slide } = this.state;
        const { build, categories, toggle, days, save } = this.props;

        let bodyClasses = ["scheduler-body col-md-12"];
        let buildClasses = ["btn btn-danger"]

        if (slide) {
            bodyClasses.push("slide-top")
            buildClasses.push("d-none");
        }

        return (
            <div className="scheduler">
                <div className="scheduler-overlay"></div>
                <div className="row scheduler-wrapper">
                    <header className="scheduler-header col-md-12 d-flex justify-content-between">
                        <h1>Automatic Workout Scheduler</h1>
                        <div className="close-scheduler" onClick={toggle}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </header>
                    <div className={bodyClasses.join(" ")}>
                        <div className="row">
                            <div className="col-md-4">
                                <h3>Duration</h3>
                                <DateRangePicker
                                    startDate={this.state.startDate}
                                    orientation="vertical"
                                    startDateId="scheduler_state_date"
                                    endDate={this.state.endDate}
                                    endDateId="scheduler_end_date"
                                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                />
                            </div>
                            <div className="col-md-4">
                                <h3>Patterns</h3>

                                <h4>Day Pattern ({this.state.catPattern})</h4>
                                <ul className="list-group list-group-flush pattern">
                                    <li className="list-group-item" onClick={() => this.addCategory('A')}>Antifragilize</li>
                                    {(categories).map((c, i) => (
                                        <li className="list-group-item"
                                            key={"schedule-categories-" + i}
                                            onClick={() => this.addCategory(c.name.substring(0, 1).toUpperCase())}>{c.name}</li>
                                    ))}
                                </ul>

                                <h4 className="mt-3">Workout Pattern ({this.state.workoutPattern})</h4>
                                <ul className="list-group list-group-flush pattern">
                                    <li className="list-group-item"
                                        onClick={() => this.addWorkout('A')}>Antifragilize</li>
                                    <li className="list-group-item"
                                        onClick={() => this.addWorkout('W')}>Workout</li>
                                    <li className="list-group-item"
                                        onClick={() => this.addWorkout('R')}>Recovery / Warmups</li>
                                </ul>
                            </div>

                            <div className="col-md-4">
                                <h3>Rest</h3>
                                <div className="row rest-days">
                                    <div className="col-sm-2">
                                        <input type="number"
                                            defaultValue={this.state.restDays}
                                            max="3"
                                            min="1"
                                            maxLength="1"
                                            className="form-control mr-1"
                                            onChange={(event) => this.setRestDays(event.target.value)} />
                                    </div>
                                    <div className="col-sm-4">
                                        <h4>Rest Day(s)</h4>
                                    </div>

                                    <div className="col-sm-2">
                                        <h4>Every</h4>
                                    </div>
                                    <div className="col-sm-2">
                                        <input type="number"
                                            defaultValue={this.state.skip}
                                            max="7"
                                            min="1"
                                            maxLength="1"
                                            className="form-control mr-1"
                                            onChange={(event) => this.setSkipDays(event.target.value)} />
                                    </div>
                                    <div className="col-sm-2">
                                        <h4>Days</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={(days && days.length > 0) && !slide ? "mt-5" : 'd-none'}>&nbsp;</div>

                    <div className={(days && days.length > 0) || slide ? "schedule-toggler" : "d-none"}>
                        <FontAwesomeIcon icon={(days && days.length > 0) && slide ? faAngleUp : faAngleDown} size="2x" color="white" onClick={this.toggleScreens} />
                    </div>

                    <div className={(days && days.length > 0) && slide ? "schedule-days w-100" : "d-none"}>
                        <div className="row">
                            {(days || []).map((day, i) => (
                                <div className="col-4 schedule-day" key={"day-schedule-" + i}>
                                    <h4 className="d-flex justify-content-between">
                                        {day.day.toLocaleDateString()}
                                        <FontAwesomeIcon icon={faTrashAlt} className="close-scheduler day-close" />
                                    </h4>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                {day.name}
                                            </h5>
                                        </div>
                                        <ul className="list-group list-group-flush">
                                            {(day.workouts || []).map((workout, j) => (
                                                <li className="list-group-item" key={"workout-schedule-" + j}>
                                                    {workout.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <button type="button" className={(days && days.length > 0) && !slide ? 'btn btn-warning' : 'd-none'} id="save" onClick={() => save(days)}>
                    {'Save'}
                </button>
                <button type="button" className={buildClasses.join(" ")} id="build" onClick={() => build(this.state, this.toggleScreens)}>
                    {'Build Schedule'}
                </button>
            </div>
        )
    }
}

export default Scheduler
