import React from 'react'
import NavigationItems from '../NavigationItems/NavigationItems';
import './SideDrawer.css';

const sideDrawer = (props) => {
    let attachedClasses = ["off-canven-menu", "Closed"];

    if (props.open) {
        attachedClasses = ["off-canven-menu", "Open"];
    }

    return (
        <nav className={attachedClasses.join(' ')}>
            <span className="close-icon" onClick={props.closed}>
                <i className="ti-close"></i>
            </span>
            <NavigationItems />
        </nav>
    )
}

export default sideDrawer;
