import { firestore } from '../../../firebase';
import _ from 'lodash';
import TodoistService from "./todoist.service";
import StringHelper from '../helpers/string.helpers';
import moment from 'moment';

let dashboardCollection = firestore.collection('dashboards');

const FirebaseService = {
    getFromDB: async () => {
        let itemsCollection = firestore.collection('items');

        return await itemsCollection.get()
            .then(snapshot => {
                console.log('Success: Retrieved documents');

                const source = snapshot.metadata.fromCache ? "local cache" : "server";

                console.log("Data came from " + source);

                return snapshot;
            })
            .catch(err => {
                console.log("Error getting documents", err);
            });
    },

    getDashboard: async (dashboard) => {
        let dashboardRef = dashboardCollection.doc(dashboard);

        return await dashboardRef.get()
            .then(dash => {
                console.log('Success: Retrieved dashboard ' + dashboard);

                return dash.data();
            })
            .catch(err => {
                console.log("Error getting dashboard", err);
            });
    },

    getWorkoutInformation: async (id) => {
        let usersRef = dashboardCollection
            .doc('fitness')
            .collection("people").doc(id.toString());

        return await usersRef.get()
            .then(workouts => {
                // console.log('Success: Retrieved user ' + id);

                return workouts.data();
            })
            .catch(err => {
                console.log("Error getting dashboard", err);
            })
    },

    filterWorkouts: async (id, value) => {
        return await FirebaseService.getWorkoutInformation(id)
            .then(w => {
                let workout_definitions = [...w.workout_definitions];

                let wDefinitions = [];
                if (value) {
                    wDefinitions = _.filter(workout_definitions, workout => {
                        const v = value.toLowerCase();
                        const nameMatch = (workout.name || '').toLowerCase().indexOf(v) > -1;
                        const noteMatch = (workout.note || '').toLowerCase().indexOf(v) > -1;

                        return nameMatch || noteMatch;
                    });
                } else {
                    wDefinitions = workout_definitions;
                }

                let filteredWorkouts = wDefinitions.slice(0, 15);

                return filteredWorkouts;
            });
    },

    uploadFitNotesExerciseDefinitions: async (exercise_definitions, userId) => {
        let usersRef = dashboardCollection
            .doc('fitness')
            .collection("people").doc(userId.toString());

        firestore.runTransaction(async transaction => {
            const doc = await transaction.get(usersRef);
            const data = doc.data();
            if (!data.exercise_definitions) {
                transaction.set(usersRef, {
                    exercise_definitions: exercise_definitions,
                    days: data.days || [],
                    workout_definitions: data.workout_definitions || []
                });
            }
            else {
                const eDefinitions = data.exercise_definitions.concat(exercise_definitions);

                transaction.update(usersRef, {
                    exercise_definitions: eDefinitions
                });
            }
        }).then(() => {
            console.log("Transaction successfully committed!");
        }).catch(err => {
            console.log("Transaction failed: ", err);
        })
    },

    addOrUpdateDay: async (day, userId) => {
        let usersRef = dashboardCollection
            .doc('fitness')
            .collection("people").doc(userId.toString());

        return firestore.runTransaction(async transaction => {
            const doc = await transaction.get(usersRef);
            const data = doc.data();
            let days = data.days;

            if (!days || days.length === 0) {
                transaction.set(usersRef, {
                    exercise_definitions: data.exercise_definitions || [],
                    days: [day],
                    workout_definitions: data.workout_definitions || []
                });
            }
            else {
                const index = _.findIndex(days, thisDay => {
                    const date = moment.unix(thisDay.date.seconds).toDate();
                    const from = moment.unix(thisDay.from.seconds).toDate();
                    const to = moment.unix(thisDay.to.seconds).toDate();

                    const dayDate = moment.unix(day.date.seconds).toDate();
                    const dayFrom = moment.unix(day.from.seconds).toDate();
                    const dayTo = moment.unix(day.to.seconds).toDate();

                    const checkFrom = from.getTime() === dayFrom.getTime();
                    const checkTo = to.getTime() === dayTo.getTime();
                    const checkDate = moment(dayDate).isSame(date, "day");

                    return checkDate && checkFrom && checkTo;
                });

                if (index === -1) {
                    days[days.length] = day; // push
                } else {
                    days[index] = day;
                }

                transaction.update(usersRef, {
                    days
                });
            }
        })
    },

    addOrUpdateExercise: async (exercise, userId) => {
        let usersRef = dashboardCollection
            .doc('fitness')
            .collection("people").doc(userId.toString());

        return firestore.runTransaction(async transaction => {
            const doc = await transaction.get(usersRef);
            const data = doc.data();
            let exerciseDefinitions = data.exercise_definitions;
            if (!exerciseDefinitions || exerciseDefinitions.length === 0) {
                transaction.set(usersRef, {
                    exercise_definitions: [exercise],
                    days: data.days || [],
                    workout_definitions: data.workout_definitions || []
                });
            }
            else {
                const index = _.findIndex(exerciseDefinitions, exerciseDef => {
                    return StringHelper.cleanToLower(exerciseDef.name) ===
                        StringHelper.cleanToLower(exercise.name);
                });

                if (index === -1) {
                    exerciseDefinitions[exerciseDefinitions.length] = exercise; // push
                } else {
                    exerciseDefinitions[index] = exercise;
                }

                transaction.update(usersRef, {
                    exercise_definitions: exerciseDefinitions
                });
            }
        })
    },

    removeExercise: async (exercise, userId) => {
        let usersRef = dashboardCollection
            .doc('fitness')
            .collection("people").doc(userId.toString());

        return firestore.runTransaction(async transaction => {
            const doc = await transaction.get(usersRef);
            const data = doc.data();
            let exerciseDefinitions = data.exercise_definitions;

            const index = _.findIndex(exerciseDefinitions, exerciseDef => {
                return StringHelper.cleanToLower(exerciseDef.name) ===
                    StringHelper.cleanToLower(exercise.name);
            });

            if (index !== -1) {
                exerciseDefinitions.splice(index, 1);

                transaction.update(usersRef, {
                    exercise_definitions: exerciseDefinitions
                });
            } else {
                // do nothing
            }
        })
    },

    addOrUpdateWorkout: async (workout, userId) => {
        let usersRef = dashboardCollection
            .doc('fitness')
            .collection("people").doc(userId.toString());

        return firestore.runTransaction(async transaction => {
            const doc = await transaction.get(usersRef);
            const data = doc.data();
            let workoutDefinitions = data.workout_definitions;
            if (!workoutDefinitions || workoutDefinitions.length === 0) {
                transaction.set(usersRef, {
                    exercise_definitions: data.exercise_definitions || [],
                    days: data.days || [],
                    workout_definitions: [workout]
                });
            }
            else {
                const index = _.findIndex(workoutDefinitions, workoutDef => {
                    return StringHelper.cleanToLower(workoutDef.name) ===
                        StringHelper.cleanToLower(workout.name);
                });

                if (index === -1) {
                    workoutDefinitions[workoutDefinitions.length] = workout; // push
                } else {
                    workoutDefinitions[index] = workout;
                }

                transaction.update(usersRef, {
                    workout_definitions: workoutDefinitions
                });
            }
        })
    },

    addItemsToDB: (fetchNumber) => {
        let itemsCollection = firestore.collection('items');
        let projectsCollection = firestore.collection('projects');

        const fetches = ((fetchNumber || 1500) / 50);
        let offset = 0;

        const callTodoist = () => {
            TodoistService.get('all', "&limit=50&offset=" + offset)
                .then(d => {
                    console.log(d.items);
                    console.log(d.projects);

                    for (let i = 0; i < d.items.length; i++) {
                        const item = d.items[i];
                        itemsCollection.doc(item.id.toString()).set(item);
                    }

                    for (let key in d.projects) {
                        if (d.projects.hasOwnProperty(key)) {
                            const project = d.projects[key];
                            projectsCollection.doc(key.toString()).set(project);
                        }
                    }
                }, err => {
                    console.log(err);
                })
            offset += 50;
        }

        for (var index = 0; index < fetches; index++) {
            (function (i) {
                setTimeout(callTodoist, 1200 * i);
            })(index);
        }
    },

    addFullItemToDB: () => {
        let itemsCollection = firestore.collection('items');
        itemsCollection.get().then(data => {
            const items = data.docs;

            for (let i = 0; i < items.length; i++) {
                (function (index) {
                    const id = items[index].id;
                    setTimeout(() => {
                        TodoistService.get('item', id)
                            .then(d => {
                                console.log(d);
                                itemsCollection.doc(id).set(d.item);
                            },
                                err => {
                                    console.log(err);
                                });
                    }, 1200 * i);
                })(i);
            }
        });
    },

    addUserStatsToDB: (name) => {
        let userCollection = firestore.collection('users');

        TodoistService.get('stats')
            .then(d => {
                console.log(d);
                userCollection.doc(name).set(d);
            });
    },

    saveLabels: () => {
        let labelCollection = firestore.collection('labels');

        TodoistService.get('labels')
            .then(d => {
                console.log(d);
                labelCollection.doc(d.id).set(d);
            })
    },

    getUser: async (userId) => {
        let userCollection = firestore.collection('users');

        let res = await userCollection.doc(userId).get();

        return res;
    },

    setUserInfo: (id, name, username, title) => {
        name = name || 'Andrew Wilson';
        username = username || 'andrew';
        title = title || 'Coolest Person Ever';

        firestore
            .collection('users')
            .doc(id)
            .set({ displayName: name, username, title });
    }
};

export default FirebaseService;