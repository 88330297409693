import React from 'react'
import SectionTitle from '../Sections/SectionTitle/SectionTitle';
import SkillSet from './Skill/SkillSet';

const Skills = (props) => {
    const { title, skills } = props;

    let skillSets = skills.map((skill, index) => {
        const { name, value } = skill;
        return <SkillSet
            key={"skillset-key-" + index}
            name={name}
            value={value}></SkillSet>;
    });

    return (
        <section className="skil_part padding_bottom">
            <div className="container">
                <SectionTitle title={title}></SectionTitle>
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                        <div className="skil_text">
                            {skillSets}
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="skil_img">
                            <img src="img/skil_img_1.png" alt="" />
                            <a href="http://www.youtube.com/watch?v=0O2aH4XLbto" className="popup_youtube"> <i
                                className="flaticon-play-button"></i> </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Skills;
