import React, { Component } from 'react';
import _ from 'lodash';
import { faPlus, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Auxilary from '../../../../hoc/Auxilary/Auxilary'
import './DefinitionContainer.css'

import FilterToolbar from '../../components/Fitness/FilterToolbar/FilterToolbar'
import DefinitionHeader from '../../components/Fitness/DefinitionHeader/DefinitionHeader'
import ExerciseDefinition from '../../components/Fitness/ExerciseDefinition/ExerciseDefinition';
import FirebaseService from '../../services/firebase.service';
import WorkoutDefinition from '../../components/Fitness/WorkoutDefinition/WorkoutDefinition';
import WorkoutCardForm from '../../components/Fitness/Forms/WorkoutCardForm/WorkoutCardForm';

class DefinitionContainer extends Component {
    state = {
        show: false,
        workoutSortable: {
            title: "Sort By",
            items: [
                {
                    name: 'Name',
                    action: () => this.sortWorkouts('name')
                },
                {
                    name: 'Category',
                    action: () => this.sortWorkouts('category')
                }
            ]
        },
        exerciseSortable: {
            title: "Sort By",
            items: [
                {
                    name: 'Name',
                    action: () => this.sortExercises('name')
                },
                {
                    name: 'Category',
                    action: () => this.sortExercises('category')
                }
            ]
        },
        exerciseFilterValue: '',
        exerciseSortValue: '',
        workoutFilterValue: '',
        workoutSortValue: '',
        exercises: [],
        workouts: [],

        blankWorkout: '',
        blankExercise: '',

        isEditable: false
    }

    constructor(props) {
        super(props);

        this.addNewWorkout = this.addNewWorkout.bind(this);

        this.filterExercises = this.filterExercises.bind(this);
        this.sortWorkouts = this.sortWorkouts.bind(this);
        this.sortExercises = this.sortExercises.bind(this);
        this.saveWorkout = this.saveWorkout.bind(this);
        this.addExerciseToWorkout = this.addExerciseToWorkout.bind(this);
        this.suggestExercise = this.suggestExercise.bind(this);
        this.toggleExercisesEditable = this.toggleExercisesEditable.bind(this);

        this.addNewExercise = this.addNewExercise.bind(this);
    }

    componentDidMount() {
        const { workout_definitions, exercise_definitions } = this.props.data;

        this.setState({
            ...this.state,
            exercises: exercise_definitions,
            workouts: workout_definitions
        });
    }

    addNewWorkout() {
        this.setState({
            ...this.state,
            blankWorkout: <WorkoutCardForm
                workout={{ id: -1 }}
                categories={this.props.categories}
                userId={this.props.user.id}
                save={this.saveWorkout}
                feelings={this.props.feelings} />
        })
    }

    saveWorkout(workout) {
        if (workout.name !== '') {
            let workouts = [...this.state.workouts];

            workouts.push(workout);

            FirebaseService.addOrUpdateWorkout(workout, this.props.user.id)
                .then(() => {
                    this.setState({
                        ...this.state,
                        blankWorkout: null,
                        workouts
                    })
                    console.log("Transaction successfully committed!");
                })
                .catch(err => {
                    console.log("Transaction failed: ", err);
                })
        } else {
            return;
        }
    }

    sortWorkouts(sortValue) {

    }

    sortExercises(sortValue) {
        this.setExerciseFilterValues(this.state.exerciseFilterValue, sortValue);
    }

    filterExercises(e) {
        const { value } = e.target;

        this.setExerciseFilterValues(value, this.state.exerciseSortValue);
    }

    setExerciseFilterValues(value, sort) {
        FirebaseService.getWorkoutInformation(this.props.user.id)
            .then(w => {
                let exercise_definitions = [...w.exercise_definitions];

                let eDefinitions = [];
                if (value) {
                    eDefinitions = _.filter(exercise_definitions, exercise => {
                        let eName = exercise.name || '';
                        let eNote = exercise.note || '';
                        const nameMatch = eName.toLowerCase().indexOf(value) > -1;
                        const noteMatch = eNote.toLowerCase().indexOf(value) > -1;

                        return nameMatch || noteMatch;
                    });
                } else {
                    eDefinitions = exercise_definitions;
                }

                if (sort) {
                    eDefinitions = _.sortBy(eDefinitions, sort);
                }

                this.setState({
                    ...this.state,
                    exerciseSortValue: sort || this.state.exerciseSortValue,
                    exerciseFilterValue: value || this.state.exerciseFilterValue,
                    exercises: eDefinitions
                });
            });
    }

    addExerciseToWorkout() {

    }

    suggestExercise() {

    }

    addNewExercise() {
        const { exercise_definitions } = this.props.data;

        let exercises = [...exercise_definitions];

        exercises.unshift({ category: 0 });

        this.setState({
            ...this.state,
            exercises: exercises
        });
    }

    toggleExercisesEditable() {
        this.setState({
            ...this.state,
            isEditable: !this.state.isEditable
        })
    }

    render() {
        let workoutDefinitions = [];
        let exerciseDefinitions = [];
        const { workouts, exercises } = this.state;

        if (workouts && workouts.length > 0) {
            workoutDefinitions = [...this.state.workouts]
                .map((workout, index) => {
                    return (
                        <WorkoutDefinition
                            workout={workout}
                            isOpen={index !== 0}
                            categories={this.props.categories}
                            index={index}
                            key={"workout-definition-" + index}
                            userId={this.props.user.id}
                            addExercise={this.addExerciseToWorkout}
                            suggestExercise={this.suggestExercise}
                            update={this.props.triggerUpdate}
                        />
                    )
                });
        }

        if (exercises && exercises.length > 0) {
            exerciseDefinitions = [...this.state.exercises]
                .map((exercise, index) => (
                    <ExerciseDefinition
                        exercise={exercise}
                        isEditable={this.state.isEditable}
                        categories={this.props.categories}
                        index={index}
                        key={"exercise-definition-" + index}
                        userId={this.props.user.id}
                        update={this.props.triggerUpdate} />
                ))
                .slice(0, 15);
        }

        return (
            <Auxilary>
                <div className="row">
                    <div className="col-lg-6 col-md-12 mb-5">
                        <DefinitionHeader title="Workouts">
                            <FilterToolbar
                                sort={this.state.workoutSortable}
                                changeInput={this.filterWorkouts}
                                value={this.state.workoutFilterValue}
                                sortValue={this.state.workoutSortValue}>
                                <button className="btn btn-danger btn-lg mr-1" onClick={this.addNewWorkout} title="Add New Workout">
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </FilterToolbar>
                        </DefinitionHeader>
                        {this.state.blankWorkout}
                        {workoutDefinitions}
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <DefinitionHeader title="Exercises">
                            <FilterToolbar
                                sort={this.state.exerciseSortable}
                                changeInput={this.filterExercises}
                                value={this.state.exerciseFilterValue}
                                sortValue={this.state.exerciseSortValue}>
                                <button className="btn btn-danger btn-lg mr-1" onClick={this.addNewExercise} title="Add New Exercise">
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                                <button className="btn btn-light btn-lg" onClick={this.toggleExercisesEditable} title="Toggle Editable">
                                    <FontAwesomeIcon icon={this.state.isEditable ? faLock : faLockOpen} />
                                </button>
                            </FilterToolbar>
                        </DefinitionHeader>
                        <ul className="list-group list-group-flush">
                            {exerciseDefinitions}
                        </ul>
                    </div>

                </div>
            </Auxilary>
        )
    }
}

export default DefinitionContainer
