import React, { Component } from 'react';
import firebase, { app } from '../../firebase';

import Auxilary from '../../hoc/Auxilary/Auxilary';
import Breadcrumb from '../../components/Navigation/Breadcrumb/Breadcrumb';

class Login extends Component {

    onSubmit = async event => {
        event.preventDefault();
        const { email, password } = event.target.elements;

        try {
            const user = await app
                .auth()
                .signInWithEmailAndPassword(email.value, password.value);

            console.log(user);

            this.props.history.push("/dashboard");
        } catch (error) {
            console.log(error);
            const m = JSON.parse(error.message);
            console.log(m.message);
        }
    }

    logOut = async () => {
        await firebase.auth().signOut();
    }

    render() {
        return (
            <Auxilary>
                <Breadcrumb title={'login'}></Breadcrumb>

                <section className="about_part single_about_padding">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-5">
                                <div className="about_img">
                                    <img src="img/about_img_1.png" alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="about_text">
                                    <h2>Login</h2>

                                    <form onSubmit={this.onSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email address</label>
                                            {/* If not email, use single-input-accent */}
                                            <input type="email" className="single-input-primary" id="email" placeholder="Enter email" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <input type="password" className="single-input-primary" id="password" placeholder="Password" />
                                        </div>
                                        <button type="submit" className="genric-btn primary">Login</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Auxilary>
        )
    }
}

export default Login
