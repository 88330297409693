export const getPriorityIcon = (priority) => {
    let icon = '';
    switch (priority) {
        case 1:
        case 'critical':
            icon = 'danger';
            break;
        case 2:
        case 'high':
            icon = 'warning';
            break;
        case 3:
        case 'medium':
            icon = 'primary';
            break;
        case 4:
        case 'low':
            icon = 'secondary';
            break;

        default:
            icon = 'secondary';
            break;
    }

    return icon;
}

export const toPriorityText = (priority) => {
    let text = '';
    switch (priority) {
        case 1:
            text = 'critical';
            break;
        case 2:
            text = 'high';
            break;
        case 3:
            text = 'medium';
            break;
        case 4:
        default:
            text = 'low';
            break;
    }

    return text;
}

export const getPrioritySizing = function (index, count, total) {
    let size = { font: 1, width: 1, height: 1, padding: 1 };

    switch (index) {
        case 3:
            size.font = (size.font * 1.5) + 'em';
            size.padding = (size.padding * .25) + 'em';
            break;
        case 2:
            size.font = (size.font * 1.25) + 'em';
            size.padding = (size.padding * .125) + 'em';
            break;
        case 1:
            size.font = (size.font * 1) + 'em';
            size.padding = (size.padding * .05) + 'em';
            break;
        default:
            size.font = (size.font * .75) + 'em';
            size.padding = (size.padding * .025) + 'em';
            break;
    }

    size.width = '80%';
    size.height = ((count / total) * 100) + "%";

    return size;
}

export const setPriorityCount = (priorityGroup) => {
    return Object.keys(priorityGroup).map(key => {
        return {
            key,
            count: priorityGroup[key]
        };
    });
}