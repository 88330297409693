import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faBrain } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'

const buttonToolbar = (props) => {
    return (
        <div
            className="btn-group d-flex justify-content-center align-items-center"
            role="group"
            aria-label={props.label}>
            <button
                type="button"
                className={props.buttonClick[0] ? "btn btn-danger btn-lg" : "d-none"}
                onClick={props.buttonClick[0]}>
                <FontAwesomeIcon icon={faPlus} />
            </button>
            <button
                type="button"
                className={props.buttonClick[1] ? "btn btn-dark btn-lg" : "d-none"}
                onClick={props.buttonClick[1]}>
                <FontAwesomeIcon icon={faBrain} />
            </button>
            <button
                type="button"
                className={props.buttonClick[2] ? "btn btn-primary btn-lg" : "d-none"}
                onClick={props.buttonClick[2]}>
                <FontAwesomeIcon icon={faCalendarAlt} />
            </button>
        </div>
    )
}

export default buttonToolbar
