import React from 'react';

const controls = (props) => {
    let classes = ["controls d-flex justify-content-center"]
    switch (props.type) {
        case 'toolbar':
            classes.push("btn-group align-items-center");
            break;
        default:
            break;
    }

    return (
        <div className={classes.join(" ")}>
            {props.children}
        </div>
    )
}

export default controls
