import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Header.css';

const header = (props) => {
    return (
        <div className="app-page-title">
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className="page-title-icon">
                        <FontAwesomeIcon icon={props.icon} />
                    </div>
                    <div>
                        {props.title}
                        <div className="page-title-subheading">{props.subTitle}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default header
