import config from "../../../config";

const TodoistService = {
    get: async (type, params) => {
        let res;
        switch (type) {
            case 'all':
                res = await fetch(config.api.todoist.get + '&annotate_notes=true' + (params || ''));
                break;
            case 'stats':
                res = await fetch(config.api.todoist.getStats);
                break;
            case 'item':
                res = await fetch(config.api.todoist.getItem + params);
                break;
            case 'labels':
                res = await fetch(config.api.todoist.getLabels);
                break;
            case 'activity':
                res = await fetch(config.api.todoist.getActivity +
                    '&event_type=completed&object_type=item' +
                    (params || ''));
                break;
            case 'active':
                res = await fetch(config.api.todoist.getActiveTasks);
                break;
            default:
                break;
        }

        return await res.json();
    }
}

export default TodoistService;