import React from 'react'

const Testimonial = (props) => {
    const { testimonial } = props;

    const { name, title, description, src } = testimonial;

    return (
        <div className="single_slider">
            <div className={"slider-thumbnails " + (src ? "" : "purple-bg")}>
                {
                    src ?
                        <img src={"images/testimonials/" + src} alt={name} className="image" /> :
                        <span className="fa fa-user-circle fa-8x"></span>
                }
                <h3>{name}</h3>
                <p>{title}</p>
            </div>
            <div className="client_review_text text-center">
                <p>
                    {description}
                </p>
            </div>
        </div>
    )
}

export default Testimonial;
