import React from 'react'
import BriefList from './BriefList/BriefList'
import BriefSummary from './BriefSummary/BriefSummary';
import BriefBarChart from './BriefBarChart/BriefBarChart';
import { getPriorityIcon, getPrioritySizing } from '../../../helpers/priority.helpers';
import BriefSimpleList from './BriefSimpleList/BriefSimpleList';

const briefs = (props) => {
    const { title, badges, data } = props;

    return (
        <div className="card mt-3">
            <div className="card-header h5">
                {title}
            </div>
            <div className="card-body">
                <div className="row">
                    <BriefList data={data.topLabels}
                        title="Most Completed Labels"
                        itemId="most-completed-labels"
                        badges={badges}
                        property="count"
                        column="lg-6 col-xl-4 mb-2" />

                    <BriefSummary title="Total Completed"
                        count={data.count}
                        classes="btn-warning"
                        column="lg-6 col-xl-4 mb-2" />

                    <BriefSimpleList title="Most Frequented Tasks"
                        data={data.frequentTasks}
                        text="Completed"
                        property="count"
                        column="lg-6 col-xl-4 mb-2" />

                    <BriefList data={data.topPriorityLabels}
                        title="Highest Priority Labels"
                        itemId="highest-priority-labels"
                        badges={badges}
                        property="critical"
                        column="lg-6 col-xl-4 mb-2" />

                    <BriefBarChart title="Priority Count"
                        data={data.priorityCount}
                        classes="priority-count"
                        transformer={getPrioritySizing}
                        getIcon={getPriorityIcon}
                        column="lg-6 col-xl-4 mb-2" />

                    <BriefSimpleList title="Highest Priority Tasks"
                        data={data.highestPriorityTasks}
                        text="Completed"
                        property="count"
                        column="lg-6 col-xl-4 mb-2" />
                </div>
            </div>
        </div>
    )
}

export default briefs
