import { firestore } from '../../firebase';
import * as actionTypes from './actionTypes';

export const setupAppSuccess = (docs) => {
    const newDocs = docs.map(doc => doc.data());
    return {
        type: actionTypes.SETUP_APP_SUCCESS,
        data: newDocs
    }
}

export const setupAppFail = (error) => {
    return {
        type: actionTypes.SETUP_APP_FAIL,
        error
    }
}

export const setupAppStart = () => {
    return {
        type: actionTypes.SETUP_APP_START
    }
}

export const setupApp = () => {
    return dispatch => {
        dispatch(setupAppStart());

        let cmsCollection = firestore.collection('cms');

        cmsCollection.get()
            .then(snapshot => {
                const source = snapshot.metadata.fromCache ? "local cache" : "server";

                console.log("Data came from " + source);

                dispatch(setupAppSuccess(snapshot.docs));
            })
            .catch(err => {
                dispatch(setupAppFail(err));
            });
    }
}

export const setupAppInit = () => {
    return {
        type: actionTypes.SETUP_APP_INIT
    }
};