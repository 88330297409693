import React, { PureComponent } from 'react'
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faRadiationAlt } from '@fortawesome/free-solid-svg-icons';
import { faMehBlank } from '@fortawesome/free-regular-svg-icons';

import './FitnessCardItem.css';
import IconHelper from '../../../../helpers/icon.helpers'
import SetsBuilder from '../../../Fitness/SetsBuilder/SetsBuilder';

class FitnessCardItem extends PureComponent {
    render() {
        const { editable, name, useEmotions, data, note, difficulty, setsBuilder } = this.props;

        let editIcon;
        if (editable) {
            editIcon = <FontAwesomeIcon icon={faPencilAlt} className="mr-2 edit-icon" />;
        }

        let emotions;
        if (useEmotions) {
            emotions = (
                <small className="emotions">
                    <FontAwesomeIcon icon={faMehBlank} size="2x" className="grow" />
                    <div className="choose-emotions-popup p-1">
                        {data.feelings.map((value, index) => (
                            <FontAwesomeIcon
                                icon={IconHelper.getFeelingIcon(value)}
                                size="2x"
                                className={index < data.feelings.length ? "grow mr-1" : 'grow'}
                                key={value}
                                alt={value} />
                        ))}
                    </div>
                </small>
            );
        }

        return (
            <li className="list-group-item align-items-center d-flex pl-0 border-top-0">
                {editIcon}
                <div className="list-group-item-body flex-column align-items-start w-100">
                    <div className="d-flex w-100">
                        {emotions}
                        <h6 className="mb-1 ml-2">{name}</h6>
                    </div>
                    <p className="mb-1">{note}</p>
                    <small>
                        {_.range(difficulty || 0).map((diffIndex) => (
                            <FontAwesomeIcon key={"difficulty" + diffIndex} icon={faRadiationAlt} size="lg" />
                        ))}
                    </small>
                </div>
                <SetsBuilder
                    builder={setsBuilder} />
            </li>
        )
    }
}

export default FitnessCardItem