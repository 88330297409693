import React from 'react'
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRadiationAlt } from '@fortawesome/free-solid-svg-icons';

import './DifficultyIcons.css'

const difficultyIcons = (props) => {
    const { difficulty, size, onSelect, defaultValue } = props;

    const noClick = () => { }

    return (
        <div id="difficulty-icons">
            {_.range(difficulty || 0).map((index) => (
                <FontAwesomeIcon
                    icon={faRadiationAlt}
                    key={'difficulty-' + index}
                    size={size}
                    className={index <= defaultValue ? "difficulty selected" : "difficulty"}
                    onClick={() => onSelect ? onSelect(index) : noClick} />
            ))}
        </div>
    )
}

export default difficultyIcons
