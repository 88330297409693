const LinkHelper = {
    getHostName: (url) => {
        let hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname

        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        }
        else {
            hostname = url.split('/')[0];
        }

        hostname = hostname.split(':')[0]; // port remove        
        hostname = hostname.split('?')[0]; // query remove

        if (hostname.indexOf("www.") > -1) {
            hostname = hostname.replace('www.', ''); // www. remove
        }

        if (hostname.indexOf(".com") > -1) {
            hostname = hostname.replace('.com', ''); // .com remove
        }

        return hostname;
    }
}

export default LinkHelper;