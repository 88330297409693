import React from 'react'
import './FilterToolbar.css'

const filterToolbar = (props) => {
    let btns;

    const { value, sortValue } = props;

    if (props.sort && props.sort.items) {
        btns = props.sort.items.map(item => {
            return (<button key={item.name + "-sort-item"} className={sortValue === item.name.toLowerCase() ? "dropdown-item active" : 'dropdown-item'} type="button" onClick={item.action}>{item.name}</button>)
        })
    }

    return (
        <div className="d-flex justify-content-center align-items-center filters">
            <div className="btn-group mr-2">
                <input type="search" className="form-control search" defaultValue={value} placeholder="Filter by name" onChange={props.changeInput} />
                <button type="button" className="btn btn-dark dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" title={props.sort.title}>
                    <span className="sr-only">{props.sort.title}</span>
                </button>
                <div className="dropdown-menu">
                    <h6 className="dropdown-header">{props.sort.title}</h6>
                    {btns}
                </div>
            </div>
            {props.children}
        </div>
    )
}

export default filterToolbar
