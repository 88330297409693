import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { loggedIn$ } from '../../firebase';

import { createStore } from 'redux';
import { Provider } from 'react-redux';

import fitnessReducer from './reducers';

import FirebaseService from './services/firebase.service';

// import "./Dashboard.css";
// import "./Dashboard.styles.css";
import NavigationBar from './components/NavigationBar/NavigationBar';
import TodoistView from './views/TodoistView/TodoistView';
import FitnessView from './views/FitnessView/FitnessView';
import GroceryView from './views/GroceryView/GroceryView';
import MiniProjectsView from './views/MiniProjectsView/MiniProjectsView';
import Auxilary from '../../hoc/Auxilary/Auxilary';

const fitnessStore = createStore(fitnessReducer);

class Dashboard extends Component {
    state = {
        showSideBar: true,
        dashboardCSS: 'Dashboard.css',
        dashboardStylesCSS: 'Dashboard.styles.css',

        user: {
            id: '',
            name: '',
            title: '',
            username: ''
        }
    }

    constructor(props) {
        super(props);
        this.handleToggle = this.handleToggle.bind(this);
    }

    componentDidMount() {
        this.props.hideNav();

        loggedIn$.subscribe(user => {
            const { uid } = user;

            FirebaseService.getUser(uid)
                .then(doc => {
                    if (!doc.exists) {
                        console.log("No document");
                    } else {
                        const { displayName, title, username } = doc.data();

                        this.setState({
                            ...this.state,
                            user: {
                                id: uid,
                                name: displayName,
                                title: title,
                                username: username
                            }
                        });
                    }
                })
        });
    }

    handleToggle() {
        this.setState({
            ...this.state,
            showSideBar: !this.state.showSideBar
        })
    }

    render() {
        return (
            <Auxilary>
                <link rel="stylesheet" type="text/css" href={this.state.dashboardCSS} />
                <link rel="stylesheet" type="text/css" href={this.state.dashboardStylesCSS} />

                <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                    <NavigationBar user={this.state.user} />
                    <div className="app-main">
                        <Route exact path='/dashboard' component={() => <TodoistView handleToggle={this.handleToggle} user={this.state.user} showSideBar={this.state.showSideBar} />} />
                        <Route path='/dashboard/fitness' component={
                            () =>
                                <Provider store={fitnessStore}>
                                    <FitnessView handleToggle={this.handleToggle} user={this.state.user} showSideBar={this.state.showSideBar} />
                                </Provider>
                        } />
                        <Route path='/dashboard/groceries' component={() => <GroceryView handleToggle={this.handleToggle} user={this.state.user} showSideBar={this.state.showSideBar} />} />
                        <Route path='/dashboard/mini' component={() => <MiniProjectsView handleToggle={this.handleToggle} user={this.state.user} showSideBar={this.state.showSideBar} />} />
                    </div>
                </div>
            </Auxilary>
        )
    }
}

export default withRouter(Dashboard);
