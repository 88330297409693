import React from 'react'

import siteLogo from '../../assets/images/logo/logo.png';
import './Logo.css';

const logo = (props) => {
    return (
        <div className={"logo main-site-logo"}>
            <a href="/"><img src={siteLogo} alt="Andrew Wilson" /></a>
        </div>
    )
}

export default logo;
