import React from 'react';
import './BriefBarChart.css'
import Bar from './Bar/Bar';

const briefBarChart = (props) => {
    const { title, data, classes, transformer, getIcon, column } = props;
    let barClasses = ["col-3 d-flex flex-column justify-content-end align-items-center"]
    if (classes) {
        barClasses.push(classes);
    }

    let bars;
    if (data) {
        let total = data.reduce((max, p) => p.count > max ? p.count : max, data[0].count);

        bars = data.map((item, index) => (
            <Bar item={item}
                index={index}
                classes={barClasses}
                transformer={transformer}
                getIcon={getIcon}
                key={title + index}
                total={total} />
        ));
    }

    return (
        <div className={`col-` + (column || 4)}>
            <div className="card-title text-center pb-2 mb-3">
                {title}
            </div>
            <div className="row">
                {bars}
            </div>
        </div>
    )
}

export default briefBarChart
