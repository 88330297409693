import { faHeartbeat, faBed, faDumbbell, faPeopleCarry, faGrinAlt, faLaughWink, faSurprise, faFrown } from "@fortawesome/free-solid-svg-icons";

const IconHelper = {
    getCategoryIcon: (category) => {
        let icon;

        switch (category) {
            case 0:
            case '0':
            case 'heartbeat':
                icon = faHeartbeat;
                break;
            case 1:
            case '1':
            case 'muscle':
                icon = faDumbbell;
                break;
            case 2:
            case '2':
            case 'bodyweight':
                icon = faPeopleCarry;
                break;
            case 3:
            case '3':
            case 'bed':
                icon = faBed;
                break;
            default:
                break;
        }

        return icon;
    },
    getFeelingIcon: (feeling) => {
        let icon;
        switch (feeling) {
            case 'Just Right':
                icon = faLaughWink;
                break;
            case 'Tough':
                icon = faSurprise;
                break;
            case 'Impossible':
                icon = faFrown;
                break;
            case 'Easy':
            default:
                icon = faGrinAlt;
                break;
        }
        return icon;
    }
}

export default IconHelper;