import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogleDrive } from '@fortawesome/free-brands-svg-icons'
import { faSync, faAngleRight, faAngleDoubleRight, faTag, faChartLine, faTable, faChartArea } from '@fortawesome/free-solid-svg-icons'
import { faUser, faChartBar } from '@fortawesome/free-regular-svg-icons'

import FirebaseService from '../../services/firebase.service'
import GoogleService from '../../services/google.service'

import BadgeHelpers from '../../helpers/badge.helpers'
import TaskHelpers from '../../helpers/task.helpers'

import Auxilary from '../../../../hoc/Auxilary/Auxilary'
import SideBar from '../../components/SideBar/SideBar'
import Actions from '../../components/SideBar/Actions/Actions'
import ListItem from '../../components/SideBar/ListItem/ListItem'
import Cloud from '../../components/SideBar/Cloud/Cloud'
import Header from '../../components/Header/Header'
import Panel from '../../components/Panel/Panel'
import Controls from '../../components/Panel/Controls/Controls'
import Control from '../../components/Panel/Controls/Control/Control'
import Briefs from '../../components/Panel/Briefs/Briefs'
import Card from '../../components/Panel/Card/Card'

class TodoistView extends Component {
    state = {
        totalToRetrieve: 1600,
        display: 'summary',

        tasks: [],
        error: null,
        badges: [],
        topFive: [],

        thisWeek: {
            week: [],
            topLabels: [],
            count: 0,
            frequentTasks: [],
            topPriorityLabels: [],
            priorityCount: [],
            highestPriorityTasks: []
        }
    }

    componentDidMount() {
        this.getGoogleItems();
    }

    getGoogleItems() {
        GoogleService.get('todos')
            .then(data => {
                let values = data.valueRanges[0].values;
                let badges = BadgeHelpers.toBadges(values);
                let tasks = TaskHelpers.toTasks(values, badges);

                let topFive = badges
                    .sort(TaskHelpers.dynamicSort("-count"))
                    .slice(0, 5);

                let thisWeek = TaskHelpers.getThisWeek(tasks);

                this.setState({ tasks, badges, topFive, thisWeek });
            });
    }

    changeWeekDisplay(display) {
        this.setState({
            ...this.state,
            display: display
        });
    }

    render() {
        let weekChildren = [];

        if (this.state.thisWeek.week.length > 0) {
            switch (this.state.display) {
                case 'summary':
                    weekChildren.push(<Briefs title="Wrap Up"
                        badges={this.state.badges}
                        data={this.state.thisWeek}
                        key="briefs" />)

                    weekChildren = weekChildren.concat(this.state.thisWeek.week.map((item, index) => (
                        <Card title="Tasks Completed"
                            item={item}
                            key={"tasks-completed" + index}
                            style={{
                                background: '#' + BadgeHelpers.getBadgeFromLabel(this.state.badges, item.key).color,
                                color: 'white'
                            }}
                            footer={<b className="text-muted" > Total Completed: {item.count}</b>} />
                    )));
                    break;

                default:
                    break;
            }
        }

        return (
            <Auxilary>
                <SideBar badges={this.state.badges} handleToggle={this.props.handleToggle} display={this.props.showSideBar}>
                    <Actions header="Actions">
                        <ListItem click={() => this.getGoogleItems()} classes="btn-info" title="Refresh" icon={faGoogleDrive} />
                    </Actions>
                    <Actions header="Firebase Actions">
                        <ListItem click={this.getFirebaseItems} classes="btn-success" title="Get" icon={faSync} />
                        <ListItem click={() => FirebaseService.addUserStatsToDB(this.props.user.username)} classes="btn-primary" title="Update User" icon={faUser} />
                        <ListItem click={() => FirebaseService.addItemsToDB(this.state.totalToRetrieve)} classes="btn-warning" title="Update" icon={faAngleRight} />
                        <ListItem click={FirebaseService.addFullItemToDB} classes="btn-danger" title="Update (Full)" icon={faAngleDoubleRight} />
                    </Actions>
                    <Actions header="Filters">
                        <li>
                            <h6>
                                <FontAwesomeIcon icon={faTag} />
                                &nbsp;
                                {'Labels'}
                            </h6>
                        </li>
                    </Actions>
                    <Cloud badges={this.state.badges} />
                </SideBar>

                <div className="app-main__outer" style={{ paddingLeft: this.props.showSideBar ? '280px' : 0 }}>
                    <div className="app-main__inner">
                        <Header title="Analytics Dashboard" subTitle="This is a dashboard used to display Google Sheets Information." icon={faChartArea} />
                        <div className="row" id="spreadsheets">
                            <Panel header="This Week">
                                <Controls>
                                    <Control text="Summary"
                                        classes="btn-primary mr-1"
                                        size="lg"
                                        icon={faChartLine}
                                        action={() => this.changeWeekDisplay('summary')} />

                                    <Control text="Table"
                                        classes="btn-dark mr-1"
                                        size="lg"
                                        icon={faTable}
                                        action={() => this.changeWeekDisplay('table')} />

                                    <Control text="Chart"
                                        classes="btn-secondary"
                                        size="lg"
                                        icon={faChartBar}
                                        action={() => this.changeWeekDisplay('chart')} />
                                </Controls>

                                {weekChildren.map(el => (el))}
                            </Panel>

                            <Panel header="Top Five">
                                <Controls />
                            </Panel>
                        </div>
                    </div>
                </div>
            </Auxilary>
        )
    }
}

export default TodoistView
