import React from 'react'
import './SideBar.css';
import Bouncer from './Bouncer/Bouncer';

const sideBar = (props) => {
    return (
        <div className="app-sidebar sidebar-shadow bg-vicious-stance sidebar-text-light" style={{ left: props.display ? 0 : -260 }}>
            <div className="scrollbar-sidebar">
                <div className="app-sidebar__inner">
                    <ul className="vertical-nav-menu">
                        {props.children}
                    </ul>
                </div>
            </div>
            <Bouncer toggleBouncer={props.handleToggle} isClicked={props.display} />
        </div>
    )
}

export default sideBar
