import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import './User.css';
import '../../../Widget.css';

const user = (props) => {
    return (
        <div className="app-header-right">
            <div className="header-btn-lg pr-0">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left ml-3 header-user-info">
                            <div className="widget-heading">
                                {props.user.name}
                            </div>
                            <div className="widget-subheading">
                                {props.user.title}
                            </div>
                        </div>
                        <div className="widget-content-right header-user-info ml-3">
                            <FontAwesomeIcon icon={faUserSecret} size="2x" color="rgb(255, 255, 255, 0.8)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default user
