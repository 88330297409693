import React, { Component } from 'react'
import _ from 'lodash';
import { Route, withRouter } from 'react-router-dom';
import { faRunning, faFileImport, faHammer } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

import './FitnessView.css'

import FirebaseService from '../../services/firebase.service';
import GoogleService from '../../services/google.service';

import Auxilary from '../../../../hoc/Auxilary/Auxilary'
import Header from '../../components/Header/Header';
import SideBar from '../../components/SideBar/SideBar';
import Actions from '../../components/SideBar/Actions/Actions';
import ListItem from '../../components/SideBar/ListItem/ListItem';

import CalendarContainer from '../../containers/CalendarContainer/CalendarContainer';
import DefinitionContainer from '../../containers/DefinitionContainer/DefinitionContainer';
import ByTheNumbers from '../../components/ByTheNumbers/ByTheNumbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconHelper from '../../helpers/icon.helpers';

class FitnessView extends Component {
    _isMounted = false;

    state = {
        update: false,
        name: '',
        version: '',
        releaseDate: new Date(),
        description: '',
        header: 'Calendar',

        categories: [],
        feelings: [],

        userInformation: {}
    }

    constructor(props) {
        super(props);

        this.triggerUpdate = this.triggerUpdate.bind(this);
        this.addWorkout = this.addWorkout.bind(this);
        this.changeSubView = this.changeSubView.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        FirebaseService.getDashboard('fitness')
            .then(data => {
                if (this._isMounted && this.props.user.id) {
                    FirebaseService.getWorkoutInformation(this.props.user.id)
                        .then(w => {
                            const header = this._changeHeaderOnPath(this.props.location.pathname);

                            this.setState({
                                ...data,
                                header,
                                userInformation: { ...w }
                            });

                            console.log(this.state);
                        });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    triggerUpdate() {
        this.setState({
            ...this.state,
            update: true
        })
    }

    addWorkout() {

    }

    suggestWorkout() {

    }

    changeSubView(subView) {
        let path = '/dashboard/fitness/' + subView;

        this.props.history.push(path);

        const header = this._changeHeaderOnPath(path);

        this.setState({
            ...this.state,
            header
        })
    }

    _changeHeaderOnPath(path) {
        const view = path.split("/")[3];
        let header;
        switch (view) {
            case 'definitions':
                header = "Definitions";
                break;
            case '':
            default:
                header = "Calendar";
                break;
        }

        return header;
    }

    importExerciseDefinitions() {
        GoogleService.get('fitness')
            .then(data => {
                let values = data.valueRanges[0].values;
                const exercise_definitions = GoogleService.toExerciseDefinitions(values);

                FirebaseService.uploadFitNotesExerciseDefinitions(exercise_definitions, this.props.user.id);
            });
    }

    render() {
        const { header, userInformation, categories, feelings, name, description } = this.state;
        const { days, exercise_definitions, workout_definitions } = userInformation;
        //const { user } = this.props;

        let dayByTheNumbers;
        if (days) {
            dayByTheNumbers = (<ByTheNumbers
                type="dark"
                title={'Total Days'}
                subTitle={'Worked Out in a Row'}
                bigNumber={days.length + " day" + (days.length > 1 ? 's' : '')}
                progress={{ label: "Days", amount: days.length, total: 60, percentage: Math.floor((days.length / 60) * 100), totalLabel: 'Total Days' }} />);
        }

        let workoutsByTheNumbers;
        if (workout_definitions) {
            let groupedWorkouts = _.groupBy(workout_definitions, 'category');
            let largestCategory = _.map(groupedWorkouts, (category, catValueIndex) => { return { total: category.length, category: catValueIndex } })
                .sort((a, b) => {
                    if (a.total > b.total) {
                        return -1;
                    }
                    if (a.total < b.total) {
                        return 1;
                    }
                    return -1;
                });
            const catWorkout = largestCategory[0];
            const category = categories[catWorkout.category];
            const catPercentage = (catWorkout.total / workout_definitions.length) * 100;

            workoutsByTheNumbers = (<ByTheNumbers
                title={'Workouts'}
                subTitle={'Biggest Category'}
                bigNumber={<div>
                    {category.name}
                    <FontAwesomeIcon className="ml-3" icon={IconHelper.getCategoryIcon(catWorkout.category)} color={category.icon.color} />
                </div>}
                progress={{ label: "Workouts", amount: catWorkout.total, total: workout_definitions.length, percentage: Math.floor(catPercentage), totalLabel: 'Total Workouts' }} />);
        }

        let exerciseByTheNumbers;
        if (exercise_definitions) {
            let groupedExercises = _.groupBy(exercise_definitions, 'category');
            let largestCategory = _.map(groupedExercises, (category, catValueIndex) => { return { total: category.length, category: catValueIndex > 0 ? catValueIndex : 0 } })
                .sort((a, b) => {
                    if (a.total > b.total) {
                        return -1;
                    }
                    if (a.total < b.total) {
                        return 1;
                    }
                    return -1;
                });
            const catWorkout = largestCategory[0];
            const category = categories[catWorkout.category];
            const catPercentage = (catWorkout.total / exercise_definitions.length) * 100;

            exerciseByTheNumbers = (<ByTheNumbers
                type="dark"
                title={'Exercises'}
                subTitle={'Biggest Category'}
                bigNumber={<div>
                    {category.name}
                    <FontAwesomeIcon className="ml-3" icon={IconHelper.getCategoryIcon(catWorkout.category)} color={category.icon.color} />
                </div>}
                progress={{ label: "Exercises", amount: catWorkout.total, total: exercise_definitions.length, percentage: Math.floor(catPercentage), totalLabel: 'Total Exercises' }} />);
        }

        return (
            <Auxilary>
                <SideBar handleToggle={this.props.handleToggle} display={this.props.showSideBar}>
                    <Actions header="Views">
                        <ListItem
                            click={() => this.changeSubView('')}
                            type="flat"
                            active={header === "Calendar"}
                            title="Calendar"
                            icon={faCalendarAlt} />
                        <ListItem
                            click={() => this.changeSubView('definitions')}
                            type="flat"
                            active={header === "Definitions"}
                            title="Definitions"
                            icon={faHammer} />
                    </Actions>
                    <Actions header="Imports">
                        <ListItem click={() => this.importExerciseDefinitions()} classes="btn-danger" title="Import FitNotes" icon={faFileImport} />
                    </Actions>
                </SideBar>
                <div className="app-main__outer" style={{ paddingLeft: this.props.showSideBar ? '280px' : 0 }}>
                    <div className="app-main__inner">
                        <Header title={name + " Dashboard"} subTitle={description} icon={faRunning} />
                        <div className="row">
                            {dayByTheNumbers}
                            {workoutsByTheNumbers}
                            {exerciseByTheNumbers}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Route exact path='/dashboard/fitness' component={() => <CalendarContainer user={this.props.user}
                                    data={userInformation}
                                    categories={categories}
                                    feelings={feelings} />} />
                                <Route exact path='/dashboard/fitness/definitions' component={() => <DefinitionContainer user={this.props.user}
                                    data={userInformation}
                                    categories={categories}
                                    feelings={feelings}
                                    triggerUpdate={this.triggerUpdate} />} />
                            </div>
                        </div>
                    </div>
                </div>
            </Auxilary>
        )
    }
}

export default withRouter(FitnessView)
