import config from "../../../config";

const WeatherService = {
    get: async (type, params) => {
        let res;

        switch (type) {
            case 'zip':
                res = await fetch(config.api.weather.get + `&zip=${params},us`);
                break;

            default:
                break;
        }

        return await res.json();
    }
}

export default WeatherService