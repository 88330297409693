import React from 'react'

import './TimeInput.css'
const timeInput = (props) => {
    const { name, index, parentIndex, superIndex, changeValue } = props;
    let { value } = props;

    if (!value) {
        value = ["00", "00", "00"];
    } else {
        value = value.split(":");
    }

    return (
        <div className="time-input">
            <div className="clock-container d-flex justify-content-center align-items-center">
                <div className="clock-col">
                    <p className="clock-hours clock-timer">
                        <input type="number"
                            name={name}
                            defaultValue={value[0]}
                            onChange={(event) => changeValue(event, index, parentIndex, superIndex, 'hours')}
                            min="0"
                            max="100" />
                    </p>
                    <p className="clock-label">
                        {"Hours"}
                    </p>
                </div>
                <div className="clock-col">
                    <p className="clock-minutes clock-timer">
                        <input
                            type="number"
                            name={name}
                            defaultValue={value[1]}
                            onChange={(event) => changeValue(event, index, parentIndex, superIndex, 'minutes')}
                            min="0"
                            max="59" />
                    </p>
                    <p className="clock-label">
                        {"Minutes"}
                    </p>
                </div>
                <div className="clock-col">
                    <p className="clock-seconds clock-timer">
                        <input
                            type="number"
                            name={name}
                            defaultValue={value[2]}
                            onChange={(event) => changeValue(event, index, parentIndex, superIndex, 'seconds')}
                            min="0"
                            max="59" />
                    </p>
                    <p className="clock-label">
                        {"Seconds"}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default timeInput
