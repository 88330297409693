import React from 'react'
import Auxilary from '../../../hoc/Auxilary/Auxilary'

const PanelLeft = () => {
    return (
        <Auxilary>
            <section className="about_part single_about_padding">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-5">
                            <div className="about_img">
                                <img src="img/about_img_1.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about_text">
                                <h2>about me</h2>
                                <div className="about_text_iner">
                                    <p>
                                        <span>
                                            Place very blessed second meat them So meat very cle stars metal darkness grass,
                                            midst be yield that after second place fruit made fourth likeness living
                                            creepeth italian multiply. That after second place fruit made fourth likeness
                                            living. Blessed second meat them So meat very cle stars metal darkness grass.
                                        </span>
                                    </p>
                                </div>
                                <p>
                                    Blessed second meat them So meat very cle stars metal darkness grass, midst be yield.
                                    Blessed second.
                                </p>
                                <div className="about_btn">
                                    <a href="/"><img src="img/Sign.png" alt="#" /></a>
                                    <a href="/" className="btn_2">more about me</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Auxilary>
    )
}

export default PanelLeft
