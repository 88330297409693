import config from "../../../config";
import _ from 'lodash';

const GoogleService = {
    get: async (type) => {
        let res;
        switch (type) {
            case 'todos':
                res = await fetch(config.api.google.sheets.getTodos);
                break;
            case 'fitness':
                res = await fetch(config.api.google.sheets.getFitNotes);
                break;
            default:
                break;
        }

        return await res.json();
    },

    toExerciseDefinitions: (rawData) => {
        const headers = rawData.splice(0, 1)[0];

        let mappedData = rawData.map((row => {
            let newRow = {};
            for (let rowIndex = 0; rowIndex < headers.length; rowIndex++) {
                const val = row[rowIndex] || '';
                const header = headers[rowIndex].toLowerCase();
                newRow[header] = val;
            }
            return newRow;
        }));

        let groupedExercises = _.groupBy(mappedData, 'exercise');

        let exercise_definitions = [];
        let exerciseId = 0;
        for (const exerciseKey in groupedExercises) {
            let exercise = {};
            if (groupedExercises.hasOwnProperty(exerciseKey)) {
                const exercisesArray = groupedExercises[exerciseKey];
                for (let i = 0; i < exercisesArray.length; i++) {
                    const exerciseObj = exercisesArray[i];
                    if (exercise.name !== exerciseObj.exercise) {
                        exercise = {
                            id: exerciseId,
                            name: exerciseObj.exercise,
                            category: -1,
                            records: [],
                            note: exerciseObj.category,
                            link: '',
                            uses_each_side: false
                        }

                        const groupedDates = _.groupBy(exercisesArray, 'date');

                        for (const dateKey in groupedDates) {
                            if (groupedDates.hasOwnProperty(dateKey)) {
                                let record = {
                                    date: dateKey,
                                    sets: []
                                };

                                const dateArray = groupedDates[dateKey];

                                for (let j = 0; j < dateArray.length; j++) {
                                    const date = dateArray[j];

                                    record.sets.push({
                                        weight: date['weight (lbs)'],
                                        reps: date.reps,
                                        distance: date.distance,
                                        time: date.time
                                    })
                                }

                                exercise.records.push(record);
                            }
                        }
                    }
                }
            }
            exercise_definitions.push(exercise);
            exerciseId++;
        }

        return exercise_definitions;
    }
}

export default GoogleService;