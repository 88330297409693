import React from 'react'

const briefSimpleList = (props) => {
    const { title, text, property, data, column } = props;

    let list;
    if (data && data.length > 0) {
        list = data.map((task, index) => {
            return (
                <li key={index + task.content} className="d-flex justify-content-between mb-2">
                    {task.content}
                    <b className="text-muted"> {text} {task[property]}</b>
                </li>
            )
        })
    }

    return (
        <div className={`col-` + (column || 4)}>
            <div className="card-title text-center pb-2 mb-3">
                {title}
            </div>
            <ul>
                {list}
            </ul>
        </div>
    )
}

export default briefSimpleList
