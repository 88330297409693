import React, { PureComponent } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faEyeSlash, faLock, faLockOpen, faEdit, faFistRaised, faHandshake, faSave, faBan, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import './ExerciseDefinition.css'
import IconHelper from '../../../helpers/icon.helpers';
import ArrayHelper from '../../../helpers/array.helpers';

import LineGraphChart from '../../LineGraphChart/LineGraphChart';
import FirebaseService from '../../../services/firebase.service';

import RecordSet from './RecordSet/RecordSet';

class ExerciseDefinition extends PureComponent {
    state = {
        showDetails: false,
        editable: false,
        showVideo: false,

        save: true
    }

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.toggleVideoDisplay = this.toggleVideoDisplay.bind(this);
        this.changeCategory = this.changeCategory.bind(this);

        this.toggleSaveable = this.toggleSaveable.bind(this);
        this.remove = this.remove.bind(this);
    }

    handleClick() {
        if (!this.state.editable && !this.props.isEditable) {
            this.setState({
                ...this.state,
                showDetails: !this.state.showDetails
            })
        }
    }

    toggleEdit() {
        this.setState({
            ...this.state,
            editable: !this.state.editable
        })
    }

    remove() {
        FirebaseService.removeExercise(this.props.exercise, this.props.userId)
            .then(() => {
                this.props.update(); // for now
                console.log("Transaction successfully committed!");
            })
            .catch(err => {
                console.log("Transaction failed: ", err);
            })
    }

    handleOnChange(e) {
        const { editable, save } = this.state;
        const { isEditable } = this.props;

        if ((editable || isEditable) && save) {
            const { value, name } = e.target;

            setTimeout(() => {
                if (name === 'uses_both_sides') {
                    this.props.exercise[name] = !this.props.exercise[name];
                } else {
                    this.props.exercise[name] = value;
                }
                FirebaseService.addOrUpdateExercise(this.props.exercise, this.props.userId)
                    .then(() => {
                        if (name === 'uses_both_sides' || name === 'link' || name === 'category') {
                            this.props.update();
                        }
                        console.log("Transaction successfully committed!");
                    })
                    .catch(err => {
                        console.log("Transaction failed: ", err);
                    })
            }, 350);
        }
    }

    toggleVideoDisplay() {
        this.setState({
            ...this.state,
            showVideo: !this.state.showVideo
        })
    }

    toggleSaveable() {
        this.setState({
            ...this.state,
            save: !this.state.save
        });
    }

    changeCategory() {
        const cat = parseInt(this.props.exercise['category'], 10)
        const exerciseCategory = cat === -1 ? 0 : cat;

        let categories = [...this.props.categories];
        const { newIndex } = ArrayHelper.next(categories, exerciseCategory);

        this.handleOnChange({ target: { name: 'category', value: newIndex } });
    }

    render() {
        if (!this.props.exercise) {
            return <span>Loading. . .</span>
        }

        const showClass = (this.state.showDetails) ? 'show' : '';
        const { exercise, index, categories, isEditable } = this.props;
        let { name, note, records, types, link, embedId } = exercise;

        let recordSets = (records || []).map(record => {
            let sets = [...record.sets];
            sets.sort(ArrayHelper.sortSets);

            let bestSet = sets.length > 0 ? sets[0] : {};

            return {
                count: record.sets.length,
                date: record.date,
                bestSet,
                sets
            }
        })

        if (!types) {
            types = ['weight']
        }

        if (!embedId) embedId = 'AOpi-p0cJkc';

        let mainType = types[0];

        let recordSet = _.orderBy(recordSets, (set) => { return set.bestSet[mainType]; }, ['desc'])[0];
        let recordLabels = recordSets.map(record => moment(record.date).format("MMMM Do, YYYY"));
        let recordValues = recordSets.map(record => _.max(record.sets.map(set => parseInt(set[mainType], 10) || 0)));

        const catIndex = parseInt(exercise.category, 10);
        const cat = catIndex === -1 || catIndex > categories.length - 1 ? 0 : exercise.category;
        const { icon, background } = categories[cat];

        let classes = ["list-group-item"];

        if (index === 0) {
            classes.push("border-top-0");
        }

        let usesBothSides = <FontAwesomeIcon
            onClick={() => this.handleOnChange({ target: { value: exercise.uses_both_sides, name: 'uses_both_sides' } })}
            name="uses_both_sides"
            className={(this.state.editable || isEditable ? "m-1" : "mr-1") + " both-sides"}
            icon={exercise.uses_both_sides ? faHandshake : faFistRaised}
            title={(exercise.uses_both_sides ? 'Each set must be done by' : 'Each set uses') + ' both sides of the body'} />

        return (
            <li className={classes.join(' ')}>
                <div className="d-flex">
                    <div className="category mr-2 d-flex align-items-center justify-content-center" style={{ backgroundColor: background }}>
                        <FontAwesomeIcon
                            icon={IconHelper.getCategoryIcon(icon.name)}
                            style={{ color: icon.color }}
                            size='lg'
                            onClick={this.changeCategory} />
                    </div>
                    <div className="list-group-item-body flex-column align-items-start w-100">
                        <div className="d-flex w-100 justify-content-between">
                            {this.state.editable || isEditable ?
                                <div className="btn-group w-75 mb-3">
                                    {usesBothSides}
                                    <FontAwesomeIcon icon={faEdit} className="m-1" />
                                    <input
                                        defaultValue={name}
                                        name="name"
                                        className="h-input w-100"
                                        placeholder="Set name"
                                        onChange={this.handleOnChange} />
                                </div> :
                                <h6 className="mb-1">
                                    {usesBothSides}
                                    {name}
                                </h6>}
                            <div>
                                <FontAwesomeIcon
                                    icon={this.state.showDetails ? faEye : faEyeSlash}
                                    onClick={this.handleClick}
                                    className="view-more mr-2" />
                                <FontAwesomeIcon
                                    icon={this.state.editable || isEditable ? faLockOpen : faLock}
                                    onClick={this.toggleEdit}
                                    className="view-more mr-2" />
                                <span className="fa-layers fa-fw mr-2" onClick={this.toggleSaveable}>
                                    <FontAwesomeIcon icon={faSave} className="view-more" />
                                    <FontAwesomeIcon icon={faBan} color="red" className={this.state.save ? "d-none" : "pointer"} />
                                </span>
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    onClick={this.remove}
                                    className="trash pointer"
                                />
                            </div>
                        </div>

                        {this.state.editable || isEditable ?
                            <div className="btn-group w-100 mb-2">
                                <FontAwesomeIcon icon={faEdit} className="m-1" />
                                <textarea
                                    defaultValue={note}
                                    rows={note ? note.match(/.{1,100}/g).length : 3}
                                    name="note"
                                    className="w-100 textarea"
                                    placeholder="Set a note"
                                    onChange={this.handleOnChange}></textarea>
                            </div> :
                            <p className="mb-1">{note}</p>}

                        <RecordSet
                            recordSet={recordSet}
                            isEditable={isEditable}
                            editable={this.state.editable}
                            showVideo={this.state.showVideo}
                            toggleVideo={this.toggleVideoDisplay}
                            onChange={this.handleOnChange}
                            link={link} />
                    </div>
                </div>
                <div id={"exercise-definition-" + index} className={"definition-chart collapse mt-3 pt-2 " + showClass}>
                    <h4>History</h4>
                    <LineGraphChart
                        title={'Max ' + mainType}
                        labels={recordLabels}
                        data={recordValues}
                        borderColor={background}
                    />
                </div>
            </li>
        )
    }
}

export default ExerciseDefinition
