import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Autocomplete.css'

export class Autocomplete extends Component {
    static propTypes = {
        options: PropTypes.instanceOf(Array).isRequired
    };

    state = {
        userInput: '',
        selectedOption: {}
    };

    onClick = (e) => {
        const index = parseInt(e.target.getAttribute('data-index'), 10);
        const { btnOnClick } = this.props;
        const selectedOption = this.props.options[index];
        this.setState({
            userInput: '',
            selectedOption
        });

        btnOnClick(selectedOption)
    };

    onAjaxChange = (e) => {
        const { value } = e.target;
        const { onChange } = this.props;

        this.setState({
            ...this.state,
            userInput: value
        });

        onChange(value);
    }

    render() {
        const {
            onClick,
            onAjaxChange,

            state: { userInput }
        } = this;

        const { placeholder, options } = this.props;
        let optionList;
        if (userInput) {
            if (options.length) {
                optionList = (
                    <ul className="options">
                        {options.map((opt, index) => {
                            let className = ['relative'];

                            return (
                                <li className={className.join(' ')}
                                    key={opt.name}
                                    data-index={index}
                                    onClick={onClick}>
                                    {opt.name}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                optionList = (
                    <div className="no-options">
                        <em>No Options!</em>
                    </div>
                );
            }
        }

        return (
            <React.Fragment>
                <div className="autocomplete-search">
                    <input
                        type="text"
                        className="search-box pl-0"
                        onChange={onAjaxChange}
                        value={userInput}
                        placeholder={placeholder}
                    />
                </div>
                {optionList}
            </React.Fragment>
        );
    }
}

export default Autocomplete;
