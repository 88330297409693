import React from 'react';
import './BriefSummary.css'

const briefSummary = (props) => {
    const { title, count, classes, column } = props;

    let btnClasses = ["btn-open-options"];
    if (classes) {
        btnClasses.push(classes);
    }

    return (
        <div className={`col-` + (column || 4)}>
            <div className="card-title text-center pb-2 mb-3">
                {title}
            </div>
            <div className="d-flex justify-content-center">
                <div className={btnClasses.join(" ")}>
                    {count}
                </div>
            </div>
        </div>
    )
}

export default briefSummary
