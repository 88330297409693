import React from 'react';

import './Toolbar.css'
import Logo from '../../Logo/Logo';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

const toolbar = (props) => {
    return (
        <header className="main_menu home_menu">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="main_menu_iner">
                            <Logo />
                            <DrawerToggle clicked={props.drawerToggleClicked} />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default toolbar;
