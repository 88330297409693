import React from 'react'
import './SetSlider.css'

const setSlider = (props) => {
    const { name, step, value, min, max, measurement, changeValue, setIndex, exerciseIndex, workoutIndex } = props;

    return (
        <div className="set-slider d-flex justify-content-between align-items-center">
            <div className="set-name">
                {name}
                {measurement}
            </div>
            <input
                type="range"
                name={name}
                onChange={(event) => changeValue(event, setIndex, exerciseIndex, workoutIndex)}
                step={step || 1}
                value={value || 0}
                min={min || 0}
                max={max || 100}></input>
            <div className="set-value">
                <input
                    type="number"
                    name={name}
                    value={value || 0}
                    onChange={(event) => changeValue(event, setIndex, exerciseIndex, workoutIndex)}
                    min={min || 0}
                    max={max || 100} />
            </div>
        </div>
    )
}

export default setSlider
