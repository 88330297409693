const SpreadsheetHelpers = {
    genColor: (seed) => {
        let color;

        color = Math.floor((Math.abs(Math.sin(seed) * 16777215)) % 16777215);
        color = color.toString(16);
        // pad any colors shorter than 6 characters with leading 0s
        while (color.length < 6) {
            color = '0' + color;
        }

        return color;
    }
}

export default SpreadsheetHelpers;