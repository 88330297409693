import React from 'react'
import './NavigationBar.css';
import Menu from './Menu/Menu';
import User from './User/User';

const navigationBar = (props) => {
    return (
        <div className="app-header header-shadow bg-vicious-stance header-text-light">
            <Menu />
            <div className="app-header__content">
                <User user={props.user} />
            </div>
        </div>
    )
}

export default navigationBar