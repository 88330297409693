import React from 'react';

import './NavigationItems.css';
import NavigationItem from './NavigationItem/NavigationItem';

const navigationItems = (props) => {
    return (
        <div className="canven-menu-warp">
            <div className="canven-menu-iner">
                <ul className={"NavigationItems"}>
                    <NavigationItem link='/' exact>Home</NavigationItem>
                    {/* <NavigationItem link='/about'>about</NavigationItem> */}
                    <NavigationItem link='/portfolio'>portfolio</NavigationItem>
                    {/* <NavigationItem link='/portfolio'>portfolio</NavigationItem> */}
                    {/* <NavigationItem link='/blog'>blog</NavigationItem> */}
                    {/* <NavigationItem link='/single-blog'>single blog</NavigationItem> */}
                    {/* <NavigationItem link='/elements'>elements</NavigationItem> */}
                    <NavigationItem link='/contact'>contact</NavigationItem>
                    <NavigationItem link='/login'>login</NavigationItem>
                </ul>
            </div>
        </div>
    )
}

export default navigationItems;
