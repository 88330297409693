import {
    REQUEST_POSTS
} from '../actions'

const exercises = (state = {
    isFetching: false,
    items: []
}, action) => {
    let returnValue;

    switch (action.type) {
        case REQUEST_POSTS:
            return {
                ...state,
                isFetching: true
            }

        default:
            returnValue = state;
            break;
    }

    return returnValue;
}

export default exercises;
