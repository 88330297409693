import React from 'react'

const CarouselItem = (props) => {
    const { src } = props;

    return (
        <div className="single_client_logo item">
            <img src={src} alt="" />
        </div>
    )
}

export default CarouselItem;
