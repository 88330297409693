import React from 'react'

const Feature = (props) => {
    const { title, image, description } = props;

    return (
        <div className="col-md-4 col-sm-6">
            <div className="single_feature_part">
                <img src={image} alt="" />
                {title ? <h3>{title}</h3> : null}
                <p dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
        </div>
    )
}

export default Feature
