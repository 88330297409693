import React, { Component } from 'react';
import Auxilary from '../../hoc/Auxilary/Auxilary';
import Breadcrumb from '../../components/Navigation/Breadcrumb/Breadcrumb';
import Experience from '../../components/Experience/Experience';
import Features from '../../components/Features/Features';
import Carousel from '../../components/Carousel/Carousel';
import { connect } from 'react-redux';
import ProgressTable from '../../components/Table/ProgressTable/ProgressTable';
import './Portfolio.css';

class Portfolio extends Component {
    render() {
        const { years, reason, carouselItems, frontEndTable, backEndTable, tableHeaders, certifications } = this.props;

        return (
            <Auxilary>
                <Breadcrumb title={'portfolio'} background={"portfolio-bg"}></Breadcrumb>

                <Carousel title="Companies" items={carouselItems} isLogo={true}></Carousel>

                <section className="skillset_part section_padding row">
                    <div className="col-lg-6">
                        <ProgressTable title={frontEndTable.title} table={frontEndTable} headers={tableHeaders}></ProgressTable>
                    </div>
                    <div className="col-lg-6">
                        <ProgressTable title={backEndTable.title} table={backEndTable} headers={tableHeaders}></ProgressTable>
                    </div>
                </section>

                <Features features={certifications} type="certifications"></Features>

                <Experience years={years} reason={reason}></Experience>
            </Auxilary>
        )
    }
}

const mapStateToProps = (state) => {
    const { appData } = state;
    const { about, portfolio } = appData;
    const { tables } = portfolio;

    return {
        years: about.years,
        reason: about.reason,
        carouselItems: portfolio.carousel,
        frontEndTable: tables.frontEnd,
        backEndTable: tables.backEnd,
        tableHeaders: tables.headers,
        certifications: portfolio.certifications,
        isFetching: state.isFetching
    };
}

export default connect(mapStateToProps)(Portfolio);
