import React from 'react';
import './Bar.css'

const bar = (props) => {
    const { item, classes, index, transformer, getIcon, total } = props;

    return (
        <div key={item.key} className={classes.join(' ')}>
            <div style={{
                width: transformer(index, item.count, total).width,
                height: transformer(index, item.count, total).height,
                padding: transformer(index, item.count, total).padding
            }}
                className={"d-flex badge-" + getIcon(item.key)}>
                <div className="align-self-end w-100">
                    {item.count}
                </div>
            </div>
            <div>
                {item.key}
            </div>
        </div>
    )
}

export default bar
