import React, { Component } from 'react'
import './ByTheNumbers.css'

class ByTheNumbers extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { title, subTitle, bigNumber, progress, type } = this.props;
        const { label, total, percentage, totalLabel, amount } = progress;

        let coloring = {};
        switch (type) {
            case 'dark':
                coloring.background = 'bg-premium-dark';
                coloring.text = 'text-white';
                coloring.progressColor = 'bg-danger';
                coloring.number = 'text-warning';
                break;
            default:
                coloring.background = '';
                coloring.text = '';
                coloring.progressColor = 'bg-primary';
                coloring.number = 'text-primary';
                break;
        }

        return (
            <div className="col-lg-6 col-xl-4">
                <div className={"card mb-3 widget-content " + coloring.background}>
                    <div className={"widget-content-outer " + coloring.text}>
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <div className="widget-heading">{title}</div>
                                <div className="widget-subheading">{subTitle}</div>
                            </div>
                            <div className="widget-content-right">
                                <div className={"widget-numbers " + coloring.number}>{bigNumber}</div>
                            </div>
                        </div>
                        <div className="widget-progress-wrapper">
                            <div className="progress-bar-xs progress-bar-animated-alt progress relative">
                                <div
                                    className={"progress-bar " + coloring.progressColor}
                                    role="progressbar"
                                    aria-valuenow={percentage}
                                    aria-valuemin="0"
                                    aria-valuemax={total}
                                    style={{ width: percentage + '%' }}></div>
                            </div>
                            <div className="text-dark bg-white tt p-1">
                                <dl className="row mb-0 text-left">
                                    <dt className="col-9 pr-1">
                                        {label}:
                                    </dt>
                                    <dd className="col-3 pl-1 pr-1 mb-0">
                                        <b>{amount}</b>
                                    </dd>
                                </dl>
                                <dl className="row mb-0 text-left">
                                    <dt className="col-9 pr-1">
                                        {totalLabel}:
                                    </dt>
                                    <dd className="col-3 pl-1 pr-1 mb-0">
                                        <b>{total}</b>
                                    </dd>
                                </dl>
                            </div>
                            <div className="progress-sub-label">
                                <div className="sub-label-left">{label}</div>
                                <div className="sub-label-right">100%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ByTheNumbers
