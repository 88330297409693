import moment from 'moment';

const DateHelpers = {
    toDate: (dateString) => {
        const cleanedDate = dateString.replace("at", "").replace("PM", ":00").replace("AM", ":00");
        const date = new Date(cleanedDate);

        return date;
    },

    getWeek: () => {
        const week = moment(moment().startOf('week').toDate()).week();

        return week;
    }
}

export default DateHelpers;