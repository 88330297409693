import React from 'react'
import SectionTitle from '../../Sections/SectionTitle/SectionTitle'
import ProgressTableRow from './ProgressTableRow/ProgressTableRow';

const ProgressTable = (props) => {
    const { title, table, headers } = props;

    let Headers = [];
    let Rows = [];

    if (table) {
        const { rows } = table;

        Headers = headers && headers.map((header, headerIndex) => <div key={'progress-table-header-' + headerIndex} className={`${header.name} ${header.klass} ${header.type}`}>{header.value}</div>)
        Rows = rows && rows.map((row, rowIndex) => <ProgressTableRow key={'progress-table-row-' + rowIndex} rowIndex={rowIndex} row={row}></ProgressTableRow>);
    }

    return (
        <div className="section-top-border">
            {/* <h3 className="mb-30">Table</h3> */}
            <SectionTitle title={title}></SectionTitle>
            <div className="progress-table-wrap">
                <div className="progress-table">
                    <div className="table-head">
                        {Headers}
                    </div>
                    {Rows}
                </div>
            </div>
        </div>
    )
}

export default ProgressTable;
