import moment from 'moment';
import _ from 'lodash';

import { toPriorityText, getPriorityIcon, setPriorityCount } from "./priority.helpers";
import DateHelpers from '../../../helpers/date';
import BadgeHelpers from "./badge.helpers";

const TaskHelpers = {
    toTasks: (values, badges) => {
        let contentCount = {};
        let tasks = values.map(task => {
            const content = task[0];

            if (contentCount[content]) {
                contentCount[content] += 1;
            } else {
                contentCount[content] = 1;
            }

            return {
                content: task[0],
                project: task[1],
                completed: DateHelpers.toDate(task[2]),
                labels: task[3] === '' ? ['None'] : task[3].replace(' ', '').split(','),
                priority: {
                    value: parseInt(task[4].replace("Priority ", ""), 10),
                    icon: getPriorityIcon(parseInt(task[4].replace("Priority ", ""), 10))
                },
                link: task[5]
            }
        }) || [];

        let sortedTasks = tasks.map(task => {
            return {
                ...task,
                labels: task.labels.map(label => {
                    return BadgeHelpers.getBadgeFromLabel(badges, label.replace(' ', ''));
                })
            }
        });

        sortedTasks.sort(TaskHelpers.dynamicSort("completed"));

        return sortedTasks;
    },

    taskToTask: (task, labels) => {
        let taskLabels = [];

        if (task.labels.length > 0) {
            taskLabels = task.labels.map(taskLabel => {
                let label = labels.filter(l => l.id === taskLabel);

                return label;
            })
        } else {
            taskLabels = ['None'];
        }

        return {
            ...task,
            labels: taskLabels,
            project: task.project_id,
            completed: DateHelpers.toDate(task.date_completed),
            priority: {
                value: task.priority,
                icon: getPriorityIcon(task.priority)
            },
            link: `https://todoist.com/showTask?id=${task.id}`
        }
    },

    itemsToTasks: (items, labels) => {
        return items.map(task => TaskHelpers.toTask(task, labels));
    },

    setTaskLabels: (tasks, badges) => {
        let sortedTasks = tasks.map(task => {
            return {
                ...task,
                labels: task.labels.map(label => {
                    return BadgeHelpers.getBadgeFromLabel(badges, label.replace(' ', ''));
                })
            }
        });

        sortedTasks.sort(TaskHelpers.dynamicSort("completed"));

        return sortedTasks;
    },

    dynamicSort: (property) => {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a, b) {
            let result = 0;

            if (a[property] < b[property]) {
                result = -1;
            } else if (a[property] > b[property]) {
                result = 1;
            }

            return result * sortOrder;
        }
    },

    groupByLabel: (data) => {
        let flattened = _.flatMap(data, (task) => {
            return _.map(task.labels, (label) => ({ ...task, labels: label }));
        })

        let grouped = _.groupBy(flattened, (task) => { return task.labels.name });

        let newArray = [];

        for (const key in grouped) {
            if (grouped.hasOwnProperty(key)) {
                const item = grouped[key];

                let priorityGroup = _.groupBy([...item], o => {
                    return toPriorityText(o.priority.value);
                });

                let newPriorityGroup = {};

                for (const priKey in priorityGroup) {
                    if (priorityGroup.hasOwnProperty(priKey)) {
                        const priGroup = priorityGroup[priKey];
                        newPriorityGroup[priKey] = priGroup.length;
                    }
                }

                const itemGroup = _([...item])
                    .groupBy('content')
                    // eslint-disable-next-line
                    .map(items => (items[0].count = items.length, items[0]))
                    .value();

                newArray.push({
                    key,
                    count: item.length,
                    data: [...itemGroup].sort((a, b) => {
                        if (a.priority.value > b.priority.value) {
                            return 1;
                        } else if (a.priority.value < b.priority.value) {
                            return -1;
                        }

                        return 0;
                    }),
                    priority: newPriorityGroup
                });
            }
        }

        const result = newArray.sort(TaskHelpers.dynamicSort("-count"));

        return result;
    },

    getPriorityCount: (tasks) => {
        let priorityGroup = _.groupBy(tasks, task => {
            return toPriorityText(task.priority.value);
        });

        let newPriorityGroup = {
            critical: 0,
            high: 0,
            medium: 0,
            low: 0
        };

        for (const priKey in priorityGroup) {
            if (priorityGroup.hasOwnProperty(priKey)) {
                const priGroup = priorityGroup[priKey];
                newPriorityGroup[priKey] = priGroup.length;
            }
        }

        return setPriorityCount(newPriorityGroup).sort(TaskHelpers.dynamicSort('count'));
    },

    groupByContent: (tasks) => {
        return _(tasks)
            .groupBy('content')
            .map(t => {
                return {
                    content: _.map(t, 'content')[0],
                    count: t.length
                };
            })
            .value()
            .sort(TaskHelpers.dynamicSort('-count'))
            .slice(0, 3);
    },

    getThisWeek: (tasks) => {
        const currentWeek = DateHelpers.getWeek();

        let filteredTasks = tasks.filter((task, i) => {
            const date = moment(task.completed);

            return (date.week() === currentWeek);
        });

        let priorityCount = TaskHelpers.getPriorityCount(filteredTasks);

        let week = TaskHelpers.groupByLabel(filteredTasks);

        let weekPriorityLabels = _.map([...week], w => {
            return {
                key: w.key,
                critical: w.priority.critical || 0,
                high: w.priority.high || 0,
                medium: w.priority.medium || 0,
                low: w.priority.low || 0
            }
        });

        const topLabels = week.slice(0, 3).map(label => { return { key: label.key, count: label.count } });
        const topPriorityLabels = weekPriorityLabels.filter(x => x.critical !== 0).sort(TaskHelpers.dynamicSort("-critical")).slice(0, 3);

        const frequentTasks = TaskHelpers.groupByContent(filteredTasks);

        let filterPriority = filteredTasks
            .map(t => { return { ...t, priority_value: t.priority.value } })
            .filter(t => t.priority_value === 1);

        const highestPriorityTasks = TaskHelpers.groupByContent(filterPriority);

        let thisWeek = {
            week,

            priorityCount,
            count: filteredTasks.length,

            topLabels,
            topPriorityLabels,

            frequentTasks: frequentTasks,
            highestPriorityTasks: highestPriorityTasks
        }

        console.log(week);

        return thisWeek;
    }
}

export default TaskHelpers;