import React from 'react'

const Client = (props) => {
    const { title, location, image } = props;

    return (
        <div className="gallery_part_item">
            <div className="grid">
                <div className="grid-sizer"></div>
                <a href={image} className="grid-item bg_img img-gal"
                    style={{ backgroundImage: `url('${image}')` }}>
                    <div className="single_gallery_item">
                        <div className="single_gallery_item_iner">
                            <div className="gallery_item_text">
                                <p>{title}</p>
                                <h3>{location}</h3>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Client
