import React from 'react'

const SkillSet = (props) => {
    const { name, value } = props;

    return (
        <div className="single_skil">
            <p>{name}</p>
            <div className="progress-bar">
                <div className="label" data-count={value}>{value}%</div>
                <div className="line">
                    <span style={{ width: value + '%' }}></span>
                </div>
            </div>
        </div>
    )
}

export default SkillSet
