import React from 'react'
import Auxilary from '../../hoc/Auxilary/Auxilary'

const Experience = (props) => {
    const { years, reason } = props;

    return (
        <Auxilary>
            <section className="cta_area section_padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="cta_iner">
                                <div className="row align-items-center">
                                    <div className="col-lg-3 col-md-3">
                                        <div className="our_expesience">
                                            <h2>{years}</h2>
                                            <p>Years Experience</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-9 col-md-9">
                                        <div className="cta_text d-flex justify-content-between align-items-center">
                                            <div className="cta_text_iner">
                                                <p>Hire me to get the best</p>
                                                <h2>
                                                    {reason}
                                                </h2>
                                            </div>
                                            <a href="/" className="genric-btn primary main-btn">hire me</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Auxilary>
    )
}

export default Experience
