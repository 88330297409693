import React, { Component } from 'react';
import Auxilary from '../../hoc/Auxilary/Auxilary';
import Breadcrumb from '../../components/Navigation/Breadcrumb/Breadcrumb';
import { connect } from 'react-redux';

class Contact extends Component {
    render() {
        const { contact } = this.props;

        const { state, city, phone, hours, support, tagline } = contact;

        return (
            <Auxilary>
                <Breadcrumb title={'contact'}></Breadcrumb>

                <section className="contact-section section_padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="contact-title">Get in Touch</h2>
                            </div>
                            <div className="col-lg-8">
                                <form className="form-contact contact_form"
                                    action="contact_process.php"
                                    method="post"
                                    id="contactForm"
                                    noValidate={"novalidate"}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">

                                                <textarea className="form-control w-100" name="message" id="message" cols="30" rows="9"
                                                    placeholder='Enter Message'></textarea>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input className="form-control" name="name" id="name" type="text" placeholder='Enter your name' />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input className="form-control" name="email" id="email" type="email" placeholder='Enter email address' />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input className="form-control" name="subject" id="subject" type="text" placeholder='Enter Subject' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mt-3">
                                        <button type="submit" className="button button-contactForm btn_4">Send Message</button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-4">
                                <div className="media contact-info">
                                    <span className="contact-info__icon"><i className="ti-home"></i></span>
                                    <div className="media-body">
                                        <h3>{state}.</h3>
                                        <p>{city}</p>
                                    </div>
                                </div>
                                <div className="media contact-info">
                                    <span className="contact-info__icon"><i className="ti-tablet"></i></span>
                                    <div className="media-body">
                                        <h3>{phone}</h3>
                                        <p>{hours}</p>
                                    </div>
                                </div>
                                <div className="media contact-info">
                                    <span className="contact-info__icon"><i className="ti-email"></i></span>
                                    <div className="media-body">
                                        <h3>{support}</h3>
                                        <p>{tagline}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Auxilary>
        )
    }
}

const mapStateToProps = (state) => {
    const { appData } = state;
    const { about } = appData;
    return {
        contact: about.contact,
        isFetching: state.isFetching
    };
}

export default connect(mapStateToProps)(Contact);
