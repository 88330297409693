import React from 'react'
import Feature from './Feature/Feature';

const Features = (props) => {
    const { features, type } = props;

    return (
        <section className="feature_part section_padding">
            <div className="container">
                <div className="row align-items-center">
                    {features.map((feature, index) => {
                        const { title, image, description } = feature;

                        return (
                            <Feature key={"feature-key-" + index}
                                title={title}
                                image={"images/" + type + "/" + image}
                                description={description}></Feature>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Features
