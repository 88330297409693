import React, { Component } from 'react';
import Banner from '../../components/Banner/Banner';
import Carousel from '../../components/Carousel/Carousel';
import Clients from '../../components/Clients/Clients';
import Experience from '../../components/Experience/Experience';
import Features from '../../components/Features/Features';
import PanelLeft from '../../components/Panels/PanelLeft/PanelLeft';
import Skills from '../../components/Skills/Skills';
import Testimonials from '../../components/Testimonials/Testimonials';
import Auxilary from '../../hoc/Auxilary/Auxilary';
import { connect } from 'react-redux';

class Landing extends Component {
    render() {
        const {
            name,
            occupation,
            years,
            reason,
            carouselItems,
            clients,
            skills,
            services,
            testimonials
        } = this.props;

        return (
            <Auxilary>
                <Banner name={"I'm " + name} occupation={occupation}></Banner>

                <PanelLeft></PanelLeft>

                <Clients title="Latest Clients" clients={clients}></Clients>

                <Features features={services} type="services"></Features>

                <Carousel title="Companies" items={carouselItems} isLogo={true}></Carousel>

                <Skills title="Latest Skills" skills={skills}></Skills>

                <Experience years={years} reason={reason}></Experience>

                <Testimonials testimonials={testimonials}></Testimonials>
            </Auxilary>
        )
    }
}

const mapStateToProps = (state) => {
    const { appData } = state;
    const { about, portfolio } = appData;
    return {
        name: about.name,
        occupation: about.occupation,
        years: about.years,
        reason: about.reason,
        carouselItems: portfolio.carousel,
        clients: portfolio.clients,
        skills: portfolio.skills,
        services: portfolio.services,
        testimonials: portfolio.testimonials,
        isFetching: state.isFetching
    };
}

export default connect(mapStateToProps)(Landing);
