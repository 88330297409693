import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './RecordSet.css'
import { faEdit, faLink, faVideo } from '@fortawesome/free-solid-svg-icons';
import LinkHelper from '../../../../helpers/link.helpers';
import VideoFrame from '../../../VideoFrame/VideoFrame';

const recordSet = (props) => {
    const { isEditable, editable, showVideo, toggleVideo, onChange, recordSet } = props;
    let { link } = props;

    let bestRecord = recordSet ? <span>Best Record: {recordSet.count} sets on {recordSet.date}</span> : <span>&nbsp;</span>;

    return (
        <small className="text-muted d-flex justify-content-between">
            {bestRecord}

            {editable || isEditable ?
                <div className="btn-group w-50">
                    <textarea
                        defaultValue={link}
                        rows={link ? link.match(/.{1,50}/g).length : 1}
                        name="link"
                        className="w-100 textarea"
                        placeholder="Add a link"
                        onChange={onChange}></textarea>
                    <FontAwesomeIcon icon={faEdit} className="m-1" />
                </div> :
                <span className={link ? 'relative' : 'd-none'}>
                    <a href={link} target="_blank" className="mr-2" rel="noopener noreferrer">
                        {LinkHelper.getHostName(link || '')}
                        <FontAwesomeIcon className="ml-2" icon={faLink} />
                    </a>
                    <FontAwesomeIcon icon={faVideo} className="view-more" onClick={toggleVideo} />
                    {showVideo ?
                        <div className="popup">
                            <VideoFrame width="100%" height="300px" link={link} />
                        </div> :
                        null}
                </span>}
        </small>
    )
}

export default recordSet
