const ArrayHelper = {
    rotate: (arr, reverse) => {
        if (reverse) arr.unshift(arr.pop());
        else arr.push(arr.shift());
        return arr;
    },
    next: (arr, curPos) => {
        const arrLength = arr.length;
        let next;
        let newIndex = curPos + 1;

        if (arrLength > 0) {
            if (arrLength <= newIndex) {
                next = arr[0];
                newIndex = 0;
            } else {
                next = arr[newIndex];
            }
        }

        return {
            next,
            newIndex
        };
    },

    sortSets: (a, b) => {
        let returnVal = -1;
        let aVal;
        let bVal;
        if (a.weight && b.weight) {
            aVal = parseInt(a.weight, 10);
            bVal = parseInt(b.weight, 10);
        } else if (a.reps && b.reps) {
            aVal = parseInt(a.reps, 10);
            bVal = parseInt(b.reps, 10);
        } else if (a.distance && b.distance) {
            aVal = parseInt(a.distance, 10);
            bVal = parseInt(b.distance, 10);
        } else {
            aVal = parseInt(a.time, 10);
            bVal = parseInt(b.time, 10);
        }

        if (aVal > bVal) {
            returnVal = -1;
        } else if (aVal < bVal) {
            returnVal = 1;
        }

        return returnVal;
    }
}

module.exports = ArrayHelper;
