import React from 'react'
import './DefinitionHeader.css'
import Auxilary from '../../../../../hoc/Auxilary/Auxilary'

const definitionHeader = (props) => {
    let { title, children } = props;

    return (
        <Auxilary>
            <header>
                <h3>{title}</h3>
                <hr />
            </header>
            {children}
        </Auxilary>
    )
}

export default definitionHeader
