import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'

class CalendarToolbar extends Component {
    render() {
        return (
            <div className="toolbar">
                <button className="btn btn-warning" onClick={this.props.toggleScheduler}>
                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                    {'Auto-Scheduler'}
                </button>
            </div>
        )
    }
}

export default CalendarToolbar
