import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const control = (props) => {
    let classes = ['btn'];
    classes.push(props.classes);

    return (
        <button className={classes.join(' ')} onClick={props.action} title={props.title}>
            {props.text}
            &nbsp;
            <FontAwesomeIcon icon={props.icon} size={props.size} />
        </button>
    )
}

export default control
