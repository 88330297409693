import React from 'react'

const videoFrame = (props) => {
    return (
        <div className="video">
            <iframe
                title="Youtube video"
                style={{
                    width: props.width,
                    height: props.height
                }}
                src={`https://www.youtube.com/embed/${props.link.split("v=")[1]}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
        </div>
    )
}

export default videoFrame
