import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { app } from './firebase';

import Layout from './hoc/Layouts/Layout';
import Contact from './containers/Contact/Contact';
import Elements from './containers/Elements/Elements';
import Landing from './containers/Landing/Landing';
import Dashboard from './containers/Dashboard/Dashboard';
import Privacy from './containers/Privacy/Privacy';
import './App.css';
import PrivateRoute from './hoc/Routing/PrivateRoute/PrivateRoute';
import Login from './containers/Login/Login';
import Portfolio from './containers/Portfolio/Portfolio';
import * as actions from './store/actions/index';
import { connect } from 'react-redux';

class App extends Component {
  state = { loading: true, authenticated: false, user: null, renderNav: true };

  constructor(props) {
    super(props);
    this.hideNav = this.hideNav.bind(this);
  }

  componentWillMount() {
    app.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({
          authenticated: true,
          currentUser: user,
          loading: false
        });
      } else {
        this.setState({
          authenticated: false,
          currentUser: null,
          loading: false
        });
      }
    });
  }

  componentDidMount() {
    this.props.onSetupApp();
  }

  hideNav() {
    this.setState({
      renderNav: false
    });
  }

  render() {
    const { authenticated, loading } = this.state;

    if (loading) {
      return <p>Loading..</p>;
    }

    return (
      <div>
        <Layout renderNav={this.state.renderNav}>
          <Switch>
            {/* <Route path="/blog" component={Blog} /> */}
            {/* <Route path="/about" component={About} /> */}
            <Route path="/contact" component={Contact} />
            <Route path="/elements" component={Elements} />
            {/* <Route path="/portfolio" component={Portfolio} /> */}
            <Route path="/privacy" component={Privacy} />
            {/* <Route path="/single-blog" component={SingleBlog} /> */}
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/login" component={Login} />
            <PrivateRoute path="/dashboard" component={Dashboard} authenticated={authenticated} hideNav={this.hideNav} />
            <Route path="/" exact component={Landing} />
          </Switch>
        </Layout>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetupApp: () => dispatch(actions.setupApp())
  }
}

export default connect(null, mapDispatchToProps)(App);
