import React from 'react'
import './Panel.css';

const panel = (props) => {
    const { header } = props;

    return (
        <div className="col-12">
            <h3 className="text-center">
                {header}
            </h3>
            {props.children}
        </div>
    )
}

export default panel
