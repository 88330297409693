// eslint-disable-next-line no-extend-native
String.prototype.trim = function () { return this.replace(/^s+|s+$/g, ""); };

const StringHelper = {
    cleanToLower: (str) => {
        if (!str) return str;

        return str.toLowerCase().trim();
    }
}

export default StringHelper;