import React, { PureComponent } from 'react'
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPlus, faBrain, faSave } from '@fortawesome/free-solid-svg-icons';

import './WorkoutCardForm.css';
import IconHelper from '../../../../helpers/icon.helpers';

import FitnessCardItem from '../../../Panel/Card/FitnessCardItem/FitnessCardItem'
import FirebaseService from '../../../../services/firebase.service';
import Autocomplete from '../../../../../../components/Autocomplete/Autocomplete';
import DifficultyIcons from '../../DifficultyIcons/DifficultyIcons';
import StringHelper from '../../../../helpers/string.helpers';

class CardForm extends PureComponent {
    state = {
        style: {
            background: '#000',
            color: 'white'
        },

        selectedCategory: 0,
        totalTime: '0',

        blankExercise: '',
        filteredExercises: [],

        workout: {
            name: '',
            category: 0,
            note: '',
            link: '',
            goal: '',
            difficulty: 0,
            duration: 0,
            exercises: []
        }
    }

    constructor(props) {
        super(props);

        this.addExercise = this.addExercise.bind(this);
        this.addPlaceholderExercise = this.addPlaceholderExercise.bind(this);
        this.updateDifficulty = this.updateDifficulty.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.filterExercises = this.filterExercises.bind(this);
        this.suggestExercise = this.suggestExercise.bind(this);

        this.addSet = this.addSet.bind(this);
        this.removeSet = this.removeSet.bind(this);
        this.changeSet = this.changeSet.bind(this);
        this.toggleSet = this.toggleSet.bind(this);
    }

    addPlaceholderExercise() {
        this.setState({
            ...this.state,
            blankExercise: <li className="list-group-item d-flex align-items-center pl-0 border-top-0 pt-0 pr-0">
                <Autocomplete
                    options={this.state.filteredExercises}
                    onChange={this.filterExercises}
                    placeholder="Search for an exercise"
                    btnOnClick={this.addExercise}
                />
            </li>
        })
    }

    filterExercises(value) {
        FirebaseService.getWorkoutInformation(this.props.userId)
            .then(w => {
                let exercise_definitions = [...w.exercise_definitions];

                let eDefinitions = [];
                if (value) {
                    eDefinitions = _.filter(exercise_definitions, exercise => {
                        const v = value.toLowerCase();
                        const nameMatch = (exercise.name || '').toLowerCase().indexOf(v) > -1;
                        const noteMatch = (exercise.note || '').toLowerCase().indexOf(v) > -1;

                        return nameMatch || noteMatch;
                    });
                } else {
                    eDefinitions = exercise_definitions;
                }

                let filteredExercises = eDefinitions.slice(0, 15);

                this.setState({
                    ...this.state,
                    blankExercise: <li className="list-group-item d-flex align-items-center pl-0 border-top-0 pt-0 pr-0">
                        <Autocomplete
                            options={filteredExercises}
                            onChange={this.filterExercises}
                            placeholder="Search for an exercise"
                            btnOnClick={this.addExercise}
                        />
                    </li>,
                    filteredExercises: filteredExercises
                });
            });
    }

    addExercise(selectedOption) {
        let exercises = [...this.state.workout.exercises];
        const index = _.findIndex(exercises, exercise => { return _.isMatch(exercise, selectedOption); });

        if (index === -1) {
            exercises.push({
                ...selectedOption,
                order: exercises.length
            });

            this.setState({
                ...this.state,
                workout: {
                    ...this.state.workout,
                    exercises: exercises
                }
            })
        }
    }

    updateDifficulty(index) {
        console.log(index);

        this.setState({
            ...this.state,
            workout: {
                ...this.state.workout,
                difficulty: index
            }
        })
    }

    suggestExercise() {

    }

    addSet(exerciseIndex) {
        let exercises = [...this.state.workout.exercises];
        let exercise = exercises[exerciseIndex];

        if (!exercise.sets) {
            exercise.sets = []
        }

        exercise.sets.push({ weight: 0, reps: 0, distance: 0, time: "00:00:00" });

        exercises[exerciseIndex] = exercise;

        this.setState({
            ...this.state,
            workout: {
                ...this.state.workout,
                exercises: exercises
            }
        })
    }

    removeSet(setIndex, exerciseIndex, workoutIndex) {
        let exercises = [...this.state.workout.exercises];

        let exercise = exercises[exerciseIndex];
        let sets = exercise.sets;
        sets.splice(setIndex, 1);

        exercises[exerciseIndex] = exercise;

        this.setState({
            ...this.state,
            workout: {
                ...this.state.workout,
                exercises: exercises
            }
        })
    }

    changeSet(e, setIndex, exerciseIndex, workoutIndex, type) {
        let { name, value } = e.target;
        let exercises = [...this.state.workout.exercises];
        let exercise = exercises[exerciseIndex];
        let sets = exercise.sets;
        let set = sets[setIndex];

        if (type) {
            let time = (set[name] || "00:00:00").split(":");
            switch (type) {
                case 'minutes':
                    time[1] = value;
                    break;
                case 'seconds':
                    time[2] = value;
                    break;
                case 'hours':
                default:
                    time[0] = value;
                    break;
            }
            value = time.join(":");
        }

        set[name] = value;

        sets[setIndex] = set;
        exercises[exerciseIndex] = exercise;

        this.setState({
            ...this.state,
            workout: {
                ...this.state.workout,
                exercises: exercises
            }
        })
    }

    toggleSet() { }

    onValueChange(e) {
        let { value, name, required } = e.target;

        if (required) {
            if (value === '') {
                e.target.className.toggle("error")
                return;
            }
        }

        let workout = { ...this.state.workout };

        workout[name] = value;

        let selectedCategory = this.state.selectedCategory;
        let style = this.state.style;

        if (name === "category") {
            let category = this.props.categories[value];
            style = {
                background: category.background,
                color: category.color
            };
            selectedCategory = parseInt(value, 10);
        }

        this.setState({
            ...this.state,
            style,
            selectedCategory,
            workout
        });
    }

    render() {
        const { categories, feelings, save } = this.props;
        const {
            style,
            selectedCategory,
            totalTime,
            workout,
            blankExercise } = this.state;
        const { exercises, difficulty } = workout;
        const { background, color } = style;

        const catIcon = IconHelper.getCategoryIcon(selectedCategory);
        const { icon } = categories[selectedCategory];

        let faIcon = <FontAwesomeIcon style={{ color: icon.color }} icon={catIcon} size="lg" />;

        return (
            <div className="card mt-3">
                <div className="card-header d-flex justify-content-between h5" style={{
                    background: background,
                    color: color
                }}>
                    <select
                        style={{
                            color: color
                        }}
                        className="transparent-input"
                        name="category"
                        defaultValue={workout.category}
                        onChange={this.onValueChange}>
                        {categories.map((category, catIndex) => {
                            return <option value={catIndex} key={"category-select-" + catIndex}>{category.name}</option>
                        })}
                    </select>
                    {faIcon}
                </div>
                <div className="card-body">
                    <h3 className="card-title mb-2 text-muted">
                        <input
                            className="transparent-input"
                            name="name"
                            placeholder="Set name of workout"
                            onChange={this.onValueChange}
                            required />
                    </h3>
                    <textarea className="transparent-input mb-2"
                        name="note"
                        placeholder="Set note of workout"
                        onChange={this.onValueChange}></textarea>
                    <textarea className="transparent-input mb-2"
                        name="goal"
                        placeholder="Set goal of workout"
                        onChange={this.onValueChange}></textarea>
                    <ul className="list-group list-group-flush">
                        {exercises.map((exercise, index) => (
                            <FitnessCardItem
                                key={exercise.name + "-key-new-exercise-" + index}
                                data={{ feelings }}
                                name={exercise.name}
                                useEmotions={false}
                                editable={false}
                                note={exercise.note}
                                difficulty={exercise.difficulty}
                                setsBuilder={{
                                    addSet: () => this.addSet(index),
                                    removeSet: this.removeSet,
                                    changeSet: this.changeSet,
                                    sets: exercise.sets,
                                    exerciseIndex: index,
                                    id: StringHelper.cleanToLower(exercise.name).replace(/\s/g, '')
                                }}
                            />
                        ))}
                        {blankExercise}
                    </ul>
                    <footer className="mt-3">
                        <div className="float-left">
                            <DifficultyIcons
                                difficulty={5}
                                size='lg'
                                defaultValue={difficulty}
                                onSelect={this.updateDifficulty} />
                        </div>
                        <div className="btn-group float-right border-bottom-0" role="group" aria-label="Quick Actions">
                            <div className="btn-diagonal-group">
                                <button type="button" className="btn btn-danger btn-lg" onClick={this.addPlaceholderExercise} title="Add Exercise">
                                    <FontAwesomeIcon icon={faPlus} />
                                    &nbsp;
                                </button>
                                <button type="button" className="btn btn-dark btn-lg" onClick={this.suggestExercise} title="Suggest Exercise">
                                    <FontAwesomeIcon icon={faBrain} />
                                    &nbsp;
                            </button>
                            </div>

                            <button type="button" className="btn btn-warning btn-lg" onClick={() => save(this.state.workout)} title="Save Workout">
                                <FontAwesomeIcon icon={faSave} />
                            </button>
                        </div>
                    </footer>
                </div>
                <div className="justify-content-between card-footer d-flex">
                    <input
                        className="transparent-input input-sm link"
                        name="link"
                        placeholder="Use link of workout"
                        onChange={this.onValueChange} />
                    <b className="text-muted">
                        <input
                            className="transparent-input input-sm time text-muted"
                            type="number"
                            name="duration"
                            min="0"
                            max="120"
                            maxLength="3"
                            defaultValue={totalTime}
                            placeholder="In minutes"
                            onChange={this.onValueChange} />
                        expected minutes <FontAwesomeIcon icon={faClock} size="lg" />
                    </b>
                </div>
            </div>
        )
    }
}

export default CardForm
