import React, { PureComponent } from 'react'

import './SetsBuilder.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import SetSlider from './SetSlider/SetSlider';
import TimeInput from '../../TimeInput/TimeInput';

class SetsBuilder extends PureComponent {
    state = {
        showSetManager: false
    }

    constructor(props) {
        super(props);

        this.toggleSets = this.toggleSets.bind(this);
    }

    toggleSets() {
        this.setState({
            showSetManager: !this.state.showSetManager
        })
    }

    render() {
        const { builder } = this.props;

        let { addSet, removeSet, changeSet, exerciseIndex, workoutIndex, sets, id } = builder;
        sets = sets || [];

        let setGroup = (sets || []).map((set, index) => {
            return (
                <div className="set mr-2" key={"set-" + index}>
                    <div className="set-box" data-toggle="collapse" data-target={"#set-" + id.replace("'", '') + '-' + index}>
                        {index + 1}
                    </div>
                    <div className="set-data p-3 collapse arrow-box" id={"set-" + id.replace("'", '') + '-' + index}>
                        <header className="d-flex justify-content-between mb-2">
                            <span>Set {index + 1} of {sets.length}</span>
                            <FontAwesomeIcon
                                className="pointer trash"
                                icon={faTrash}
                                onClick={() => removeSet(index, exerciseIndex, workoutIndex)}
                                size="lg" />
                        </header>
                        <SetSlider
                            name="weight"
                            step={0.5}
                            value={set.weight}
                            max={1000}
                            measurement=" (in lbs)"
                            setIndex={index}
                            exerciseIndex={exerciseIndex}
                            workoutIndex={workoutIndex}
                            changeValue={changeSet} />
                        <SetSlider
                            name="reps"
                            step={1}
                            value={set.reps}
                            max={125}
                            setIndex={index}
                            exerciseIndex={exerciseIndex}
                            workoutIndex={workoutIndex}
                            changeValue={changeSet} />
                        <SetSlider
                            name="distance"
                            step={100}
                            value={set.distance}
                            max={100000}
                            measurement=" (in ft)"
                            setIndex={index}
                            exerciseIndex={exerciseIndex}
                            workoutIndex={workoutIndex}
                            changeValue={changeSet} />
                        <TimeInput
                            name="time"
                            value={set.time}
                            index={index}
                            parentIndex={exerciseIndex}
                            superIndex={workoutIndex}
                            changeValue={changeSet} />
                    </div>
                </div>
            )
        });

        return (
            <div className="sets d-flex justify-content-center align-items-center p-2">
                <span className="mr-2">Sets</span>
                {sets.length > 3 ?
                    <div className="set-group relative">
                        <button type="button" className="btn mr-1" onClick={this.toggleSets}>
                            <FontAwesomeIcon icon={faEllipsisH} size='lg' />
                        </button>
                        <div className={this.state.showSetManager ? "set-manager flex-wrap absolute d-flex justify-content-center align-items-center p-2" : "d-none"}>
                            {setGroup}
                        </div>
                    </div> :
                    setGroup}
                <FontAwesomeIcon
                    icon={faPlus}
                    className="pointer"
                    onClick={addSet} />
            </div>
        )
    }
}

export default SetsBuilder

