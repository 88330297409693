import React from 'react';
import './CardItem.css';

const cardItem = (props) => {
    const { index, item } = props;
    return (
        <li key={'weekTask' + index}>
            <p>
                <span className={'badge badge-' + item.priority.icon}>{item.priority.value}</span>
                &nbsp;
                {item.content}
                <b> x {item.count} </b>
            </p>
        </li>
    )
}

export default cardItem
